// import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

// Local
import { searchBarWidth } from "../../Configs/configs";

// This component displays and handles Prefab Queries (pre-fabricated or pre-built queries)
export const PrefabSearch = (props) => {
  const handleSelectedPrefab = (event) => {
    props.handlePrefabSelection(event.target.value);
  };

  // Hide/archive query from user (add to blacklist in postgres)
  const handleClearClick = (event, value) => {
    event.stopPropagation(); // Stop event from propagating to MenuItem
    props.mutation.mutate({
      action: "hideQueryForUser",
      queryId: value,
      userId: props.pageLoadData.user_id,
    });
  };

  if (props.prefabTerm === "") {
    return <select className="search-box" />;
  }

  return (
    <FormControl size="small" sx={{ maxWidth: searchBarWidth, width: "100%" }}>
      <Select value={props.prefabTerm} onChange={handleSelectedPrefab}>
        {/* Populate the prefabqueries list from the prefabQueries.json in ./StaticAssets */}
        {props.prefabQueries.map((query) => {
          return (
            <MenuItem key={query.query_id} value={query.query}>
              <Box display="flex" justifyContent="space-between" width="100%">
                <span>{query.query_name}</span>
                {props.prefabTerm !== query.query && query.query_id < 99999 && (
                  <Tooltip title="Hide">
                    <ClearIcon
                      fontSize="small"
                      className="link"
                      onClick={(event) => handleClearClick(event, query.query_id)}
                    />
                  </Tooltip>
                )}
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
