import { Aggregation } from "../../../Configs/interfaces";
import { Station } from "../../../Models/station";

// This function creates an object of cities with the current clip count for each and the lat/long
export const buildMarkerCount = (stationAggs: Aggregation, stations: Station[]) => {
    const clipCountByLocation = {} as {
      [key: string]: { name: string, count: number; lat: number; lng: number };
    };
  // const buildMarkerCount = (stationAggs, stations) => {
  //   const clipCountByLocation = {} 
  
    // Map each station aggregation to its lat/long and doc count
    // Combine doc_count values for country-roll ups (no specific location)
    stationAggs.buckets.forEach((agg: any) => {
      const matchingStation = stations.find((station: Station) => station.stationCode === agg.key);
      if (matchingStation) {
        const { location, preciseLat, preciseLng, country, countryLat, countryLng } = matchingStation;
        const curLocation = location || country;
        if (curLocation) {
          if (clipCountByLocation[curLocation]) {
            clipCountByLocation[curLocation].count += agg.doc_count;
          } else {
            clipCountByLocation[curLocation] = {
              name: curLocation,
              count: agg.doc_count,
              lat: preciseLat || countryLat,
              lng: preciseLng || countryLng,
            };
          }
        }
      }
    });
    return clipCountByLocation;
  };
  