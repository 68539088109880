import { useQuery } from "@tanstack/react-query";
import { dev_v_prod } from "./dev_v_prod";

// async function fetchPostgresMetadata({ queryKey }) {
//   const [_key, { data }] = queryKey;
export async function fetchPostgresMetadata( data) {
  // const [_key, { data }] = queryKey;
  const station_codes = new Set();
  const program_ids = new Set();

  for (const hit of data) {
    const sourceMetadata = hit._source?.sourceMetadata;
    if (sourceMetadata) {
      const { station_code, program_id } = sourceMetadata;

      if (station_code) {
        station_codes.add(station_code);
      }

      if (program_id) {
        program_ids.add(program_id);
      }
    }
  }

  const environment = dev_v_prod();
  const pgData = await (
    await fetch(environment.fqPost, {
      method: "POST",
      body: JSON.stringify({
        getPageLoadData: true,
        stationCodes: Array.from(station_codes),
      }),
    })
  ).json();

  // const pgData = {
  //   stationMetadata: stationMetadata.response.rows,
  // };

  // if (program_ids.size > 0) {
  //   const programMetadata = await (
  //     await fetch(environment.fqPost, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         program_ids: Array.from(program_ids),
  //       }),
  //     })
  //   ).json();

  //   if (programMetadata.response) {
  //     pgData.programMetadata = programMetadata.response.rows;
  //   }
  // }

  return pgData;
}

// export function usePostgresMetadata(data) {
//   return useQuery(['postgresMetadata', { data }], fetchPostgresMetadata);
// }