// Global
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

// MUI
import { Stack, Tooltip } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import RadioIcon from "@mui/icons-material/Radio";

// Local
import { FilterControls, FilterObj, FilterTypes } from "../../Hooks/useFilters";
import { labelValuePair } from "../../Configs/interfaces";
import { debounce } from "./debounce";

interface Props {
  filterControls: FilterControls;
}

// Populate, display, and handle change on metadata filters (countries, stations)
const MetadataFilters = ({ filterControls }: Props) => {
  if (filterControls.filterOptions === undefined) {
    return null;
  }

  // Local state
  const [countryOptions, setCountryOptions] = useState([] as labelValuePair[]);
  const [stationOptions, setStationOptions] = useState([] as labelValuePair[]);
  const [countriesSelected, setCountriesSelected] = useState([] as labelValuePair[]);
  const [stationSelected, setStationSelected] = useState([] as labelValuePair[]);

  // Formatting FilterObj for multiselect
  function formatForDisplay(type: FilterTypes, values: FilterObj[] | undefined) {
    if (values === undefined) {
      return [];
    }
    return values
      .map((filter: FilterObj) => ({
        label: type === FilterTypes.Countries ? filter.country : filter.label,
        value: type === FilterTypes.Countries ? filter.value.substring(0, 2) : filter.value,
      }))
      .filter((option, index, self) => index === self.findIndex((t) => t.value === option.value));
  }

  // New filter options means reset the component
  useEffect(() => {
    if (filterControls.filterOptions !== undefined) {
      setCountryOptions(
        formatForDisplay(FilterTypes.Countries, filterControls.filterOptions.countries)
      );
      setStationOptions(
        formatForDisplay(FilterTypes.Stations, filterControls.filterOptions.stations)
      );
      setCountriesSelected(
        formatForDisplay(FilterTypes.Countries, filterControls.filterOptions.countries)
      );
      setStationSelected(
        formatForDisplay(FilterTypes.Stations, filterControls.filterOptions.stations)
      );
    }
  }, [filterControls.filterOptions]);

  // Handle user selections
  const handleCountryChange = (items: { value: string }[]) => {
    if (filterControls.filterOptions?.countries) {
      const filteredCountries = filterControls.filterOptions.countries.filter(
        (country: FilterObj) => items.some((item) => country.value.startsWith(item.value))
      );
      setCountriesSelected(formatForDisplay(FilterTypes.Countries, filteredCountries));

      // Delay (debounce) the updateFilters call
      debounce(() => {
        filterControls.updateFilters(FilterTypes.Countries, filteredCountries);
      }, 1000)();
    }
  };

  // Handle station selections
  const handleStationChange = (items: { value: string }[]) => {
    if (filterControls.filterOptions?.stations) {
      const filteredStations = filterControls.filterOptions.stations.filter((station: FilterObj) =>
        items.some((item) => station.value === item.value)
      );
      setStationSelected(formatForDisplay(FilterTypes.Stations, filteredStations));

      // Delay (debounce) the updateFilters call
      debounce(() => {
        filterControls.updateFilters(FilterTypes.Stations, filteredStations);
      }, 1000)();
    }
  };

  // Render dropdowns
  return (
    <>
      <Stack direction='row' display='flex' alignItems='center' spacing={1} sx={{ zIndex: '1' }}>

        <Tooltip title="Filter by country" placement="top">
          <PublicIcon color="primary" />
        </Tooltip>

        <MultiSelect
          className="inline-div md-dropdowns"
          options={countryOptions}
          value={countriesSelected}
          onChange={handleCountryChange}
          labelledBy="Countries"
        />
      </Stack>
      <Stack direction='row' display='flex' alignItems='center' spacing={1} px={2}>
        <Tooltip title="Filter by station" placement="top">
          <RadioIcon color="primary" />
        </Tooltip>
        <MultiSelect
          className="inline-div md-dropdowns"
          options={stationOptions}
          value={stationSelected}
          onChange={handleStationChange}
          labelledBy="Stations"
        />
      </Stack>
    </>
  );
};

export default MetadataFilters;
