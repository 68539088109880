import { dev_v_prod } from "./dev_v_prod";

export async function getProjectNames(username) {
  
  const environment = dev_v_prod();
  const response = await (
    await fetch(environment.fqPost, {
      method: "POST",
      body: JSON.stringify({
        getProjectNames: true,
        username: username,
      }),
    })
  ).json();

  const projNames = [];

  for (const row of response.response.rows) {
    projNames.push({
      project_name: row.project_name,
      project_id: row.project_id
    });
  }
  return projNames;
};
