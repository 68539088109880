// Global
import { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

// MUI / Formik
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Select,
  Typography,
  Stack,
  LinearProgress,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Local
import { formWidth, maxWidth } from "../Configs/configs";
import usePresignedUrl from "../Hooks/usePresignedUrl";
import { setToMidnight } from "../Components/DataManagement/DataCleaning/dateFormatters";
import { UploadTypes, TranscriptionLanguages } from "../Configs/enums";
import { generateRandomString } from "../Components/DataManagement/formatters";

export interface UploadFormValues {
  fileName: string;
  file: File | null;
  date: dayjs.Dayjs | null;
  fileType: string;
  language: string;
}

const initialValues: UploadFormValues = {
  fileName: "",
  file: null,
  date: null,
  fileType: UploadTypes.DrcWhatsApp,
  language: TranscriptionLanguages.Detect,
};

interface SubmitDataArgs {
  url: string | null;
  file: File;
}

const submitData = async ({ url, file }: SubmitDataArgs) => {
  if (url) {
    const response = await axios.put(url, file);
    return response;
  }
};

const keyPathPrefix = "recordings/99/";
const keyPathTime = "_00-00";
const keyPathStationName = "cd_whatsapp";

const successNote = "Remember it takes a few minutes for processing.";

const fileTypesExplainer = (
  <Box>
    <Typography variant="body1">Supported filetypes:</Typography>
    AAC, MP3, MP4, OPUS, WAV
  </Box>
);

const dateExplainer = (
  <Box>
    <Typography variant="body1">Date defaults to the file's last modified date.</Typography>
    <Typography variant="body1">Adjust for greater accuracy.</Typography>
  </Box>
);

const languageExplainer = (
  <Box>
    <Typography variant="body1">Automatically detect the language</Typography>
    <Typography variant="body1">or choose from the list.</Typography>
  </Box>
);

// Allow users to upload files with audio to the FQ database
const Upload = () => {
  const [keyPath, setKeyPath] = useState<string>(""); // S3 path
  const [fileToUpload, setFileToUpload] = useState<File | null>(null); // File
  const [uploadFeedback, setUploadFeedback] = useState<string>(""); // Axios response
  const [showLinearLoader, setShowLinearLoader] = useState<boolean>(false);

  // Get Presigned URL for S3 Obj
  const { presignedUrl, error } = usePresignedUrl(keyPath);

  // Mutation workflow for file upload
  const mutation = useMutation({
    mutationFn: submitData,
    onMutate: () => {
      setFileToUpload(null);
    },
    onSuccess: () => {
      setUploadFeedback("File uploaded successfully! " + successNote);
    },
    onError: (error: Error) => {
      setUploadFeedback(
        `Error uploading file: ${error instanceof Error ? error.message : "Unknown error"}`
      );
    },
    onSettled: () => {
      setShowLinearLoader(false);
    },
  });

  // Fire off the mutation when the presigned URL and file are ready
  useEffect(() => {
    if (presignedUrl && fileToUpload && !uploadFeedback) {
      setShowLinearLoader(true);
      setUploadFeedback("Uploading file..."); // Prevent double upload with state change
      mutation.mutate({ url: presignedUrl, file: fileToUpload });
    }
  }, [presignedUrl, uploadFeedback, fileToUpload, mutation]);

  // Generate precise filename for Frequency data processing pipeline
  function generateFileName(file: File | null, language: string, date: dayjs.Dayjs | null): string {
    if (!file || !date) {
      return `BAD FILE NAME`;
    }
    const langSyntax = language === TranscriptionLanguages.Detect ? "" : `.${language}`;
    const dateStrPrefix = date.format("YYYY/MM/DD/");
    const dateStrInline = date.format("YYYY-MM-DD");
    const randomizedString = generateRandomString(6);
    const extension = file.name.split(".").pop();
    // return `${keyPathPrefix}${dateStrPrefix}${keyPathStationName}.${dateStrInline}${keyPathTime}.${language}.${extension}`;
    return `${keyPathPrefix}${dateStrPrefix}${keyPathStationName}.${randomizedString}.${dateStrInline}${keyPathTime}${langSyntax}.${extension}`;
  }

  // User trigger for submit workflow
  const handleSubmit = (values: UploadFormValues) => {
    // let keyPath = `${keyPathPrefix}${values.date}${keyPathTime}`;
    console.log("Form values: ", values);
    setKeyPath(values.fileName);
    setFileToUpload(values.file); // Update state with the file to upload
  };

  return (
    <Box maxWidth={maxWidth} display="flex" justifyContent="center">
      <Box minWidth={600}>
        <h1>Upload</h1>

        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ setFieldValue, values }) => (
            <Form>
              <Stack
                direction="row"
                spacing={2}
                marginBottom={2}
                display="flex"
                alignItems="center"
              >
                <Field name="fileType" as={Select} label="File Type" sx={{ width: formWidth }}>
                  {Object.entries(UploadTypes).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Field>
                {fileTypesExplainer}
              </Stack>

              <Stack direction="row" columnGap={2} display="flex" alignItems="center" mb={2}>
                <input
                  accept="audio/*,video/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple={false}
                  type="file"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
                    const newDate = file ? setToMidnight(dayjs(file.lastModified)) : null;
                    const newFileName = generateFileName(file, values.language, newDate);
                    setFieldValue("file", file);
                    setFieldValue("date", newDate);
                    setFieldValue("fileName", newFileName);
                    setUploadFeedback("");
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" component="span" color="primary">
                    Choose File
                  </Button>
                </label>
                <Typography variant="body1" color="textSecondary">
                  {values.file?.name}
                </Typography>
              </Stack>
                 <Stack
                direction="row"
                spacing={2}
                marginBottom={2}
                display="flex"
                alignItems="center"
              > 
                <Field
                  name="language"
                  as={Select}
                  label="Language"
                  onChange={(value: any) => {
                    const newLang = values.file ? value.target.value : "";
                    const newFileName = generateFileName(values.file, newLang, values.date);
                    setFieldValue("language", newLang);
                    setFieldValue("fileName", newFileName);
                  }}
                  disabled={values.file === null}
                  sx={{ width: formWidth }}
                >
                  {Object.entries(TranscriptionLanguages).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Field>
                {languageExplainer}
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                marginBottom={2}
                display="flex"
                alignItems="center"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={values.date}
                    onChange={(value) => {
                      const newDate = values.file ? setToMidnight(dayjs(value)) : null;
                      const newFileName = generateFileName(values.file, values.language, newDate);
                      setFieldValue("date", newDate);
                      setFieldValue("fileName", newFileName);
                    }}
                    disabled={values.file === null}
                    maxDate={dayjs()}
                    sx={{ width: formWidth }}
                  />
                </LocalizationProvider>
                {dateExplainer}
              </Stack>
              <Box marginBottom={2}>
                {/* Automatically generated file name */}
                <Field
                  name="fileName"
                  as={TextField}
                  label="Destination Syntax"
                  fullWidth
                  disabled
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={values.file === null || uploadFeedback !== ""}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>

        <Typography variant={"body1"} my={2}>
          {uploadFeedback}
          {error?.message}
        </Typography>
        {showLinearLoader && <LinearProgress color="primary" />}
      </Box>
    </Box>
  );
};

export default Upload;
