// Global
import { useState } from "react";
import { QueryKey, QueryFunctionContext, useSuspenseInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";

// Local
import { fiveMinutesStaleTime } from "../Configs/configs";
import { opensearchData } from "../Configs/keyConstants";
import { searchTermCleaning } from "../Components/DataManagement/DataCleaning/searchTermCleaning";
import { SearchTypes } from "../Configs/enums";
import { dev_v_prod } from "../Components/APICalls/dev_v_prod";
import parseSavedCards from "../Components/DataManagement/DataCleaning/parseSavedCards";
import { DateObj, PageLoadData, UseClipsQueryParams } from "../Configs/interfaces";
import { addMetadata } from "../Components/DataManagement/addMetadata";
import { FilterObj, FilterProps } from "./useFilters";
import { mergeStationCodeFilters } from "../Components/DataManagement/DataCleaning/formatFilters";

// Business logic for /getOSData endpoint
const getClips = async ({ queryKey, pageParam = 0 }: QueryFunctionContext<QueryKey, number>) => {
  const [_key, params] = queryKey as [string, UseClipsQueryParams | null];

  if (params && params.stationCodes) {
    // Call the API, send it the match term
    const environment = dev_v_prod();
    const response = await axios.post(environment.getOSData, { ...params, page: pageParam });
    let results = response.data.response;

    return results;
  }
  return null;
};

/////
// Get Opensearch Data from fq-transcripts-alias
/////

interface Props {
  pageLoadData: PageLoadData;
  searchTerm: string;
  dateObj: DateObj;
  isOldestFirst?: boolean;
  filters: FilterProps | undefined;
  setNewFilterOptions: (values: FilterObj[]) => void;
  savedCardArray: Record<string, any>[];
}

export const useClips = (props: Props) => {
  const [resetFilters, setResetFilters] = useState<number>(0);

  let cleanedTerm = "";
  let searchType = SearchTypes.GetData;
  const pageSize = 10

  if (props.searchTerm === "GETSAVEDCARDS") {
    // TODO: logic to return early?

    searchType = SearchTypes.GetSavedCards;
  } else if (props.searchTerm === "GETLAST10") {
    searchType = SearchTypes.GETLAST10;
  } else {
    cleanedTerm = searchTermCleaning(props.searchTerm);
  }

  // Build params for API call
  const params: UseClipsQueryParams | null =
    props.searchTerm && props.pageLoadData.stations
      ? {
          searchType: searchType,
          searchTerm: cleanedTerm,
          language_pref: props.pageLoadData.language_pref,
          stationCodes: mergeStationCodeFilters(props.pageLoadData.stations, props.filters),
          startDateRange: props.dateObj.startDateRange,
          endDateRange: props.dateObj.endDateRange,
          isOldestFirst: props.isOldestFirst,
    pageSize: pageSize,
          savedCardIDs:
            searchType === SearchTypes.GetSavedCards
              ? props.savedCardArray.map((card) => card.open_search_id)
              : [],
        }
      : null;

  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useSuspenseInfiniteQuery({
    queryKey: [opensearchData, params],
    queryFn: getClips,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.totalHits > allPages?.length * pageSize ? allPages.length : undefined; // No more data
    },
    // staleTime: fiveMinutesStaleTime,
  });

  //
  // If data...
  //

  // console.log("data", JSON.stringify(data));

  if (data?.pages?.[0]?.hits?.length === 0) {
    return {
      clipData: { hits: [], aggregations: {}, totalHits: 0 },
      fetchControls: { hasNextPage, fetchNextPage, isFetchingNextPage },
      resetFilters,
    };
  }

  // Path for when a new page needs metdata to be added
  if (
    data?.pages?.[data.pages.length - 1] && // Page exists
    !(data.pages[data.pages.length - 1]?.hits[0]._source.sourceMetadata.country ?? 0) // Hits have metadata
  ) {
    // Flatten the hits from all pages
    let flatHits = data.pages.flatMap((page) => page.hits);

    if (props.searchTerm === "GETSAVEDCARDS") {
      flatHits = parseSavedCards(flatHits, props.savedCardArray);
    }

    // Send hits to addMetadata
    const flatHitsWithMetadata = addMetadata({
      pageLoadData: props.pageLoadData,
      hits: flatHits,
    });

    // Tell useState in ViewClips to reset the filters for the new data
    setResetFilters(resetFilters + 1);

    // Construct clipData with the combined pages
    const clipData = {
      hits: flatHitsWithMetadata,
      aggregations: data.pages[0].aggregations,
      totalHits: data.pages[0].totalHits,
    };
    return {
      clipData,
      fetchControls: { hasNextPage, fetchNextPage, isFetchingNextPage },
      resetFilters,
    };
  }

  // Path for when no new metadata needs adding
  const clipData = data.pages[0]
    ? {
        hits: data.pages.flatMap((page) => page.hits),
        aggregations: data.pages[0].aggregations,
        totalHits: data.pages[0].totalHits,
        resetFilters,
      }
    : { resetFilters };

  return {
    clipData,
    fetchControls: { hasNextPage, fetchNextPage, isFetchingNextPage },
    resetFilters,
  };

  // if (term === "GETSAVEDCARDS") {
  //   results = parseSavedCards(results, searchType, saved_card_array);
  // }
};
