import { dev_v_prod } from "../APICalls/dev_v_prod";
import postAlertCard from "../APICalls/postAlertCard";

const createShareAlertCard = async (
  indexID,
  username,
  highlightArray,
  highlightIndex
) => {

  // Call the API, return url
  const urlId = await postAlertCard(
    indexID,
    username,
    highlightArray,
    highlightIndex
  );

  // Pack URL with specific highlight Param
  const environment = dev_v_prod();
  const shareURL =
    environment.shareAlertsCard +
    "id=" +
    urlId +
    "&highlight=" +
    highlightIndex;

  return shareURL;
};

export default createShareAlertCard;
