// Global
import {useMemo } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import { Icon } from "leaflet";

// MUI
import { Box, Typography } from "@mui/material";

// Local
import { mapDefaultCoordinates, mapDefaultZoom, mapHeight } from "../../Configs/configs";
import calcMarkerIntensity, { DataIntensity } from "../DataManagement/Maps/calcMarkerIntensity";
import { Aggregation } from "../../Configs/interfaces";
import { Station } from "../../Models/station";
import { buildMarkerCount } from "../DataManagement/Maps/buildMarker";


interface Props {
  stationAggs: Aggregation;
  stations: Station[];
}

// Display map of evidence on Analyze Tab
// Display minimap of evidence on Theme cards
const StationMap = ({ stationAggs, stations }: Props) => {
  const dataWithIntensity = useMemo(() => {
    if (stationAggs.buckets && stations) {
      const data = buildMarkerCount(stationAggs, stations);
      // Calculate high/low concentrations of data by location
      return calcMarkerIntensity(data);
    }
    return [];
  }, [stationAggs, stations]);

  if (!dataWithIntensity.length) {
    console.log("Map data undefined");
    return null;
  }

  //   const dataWithIntensity = calcMarkerIntensity(clipCountByLocation);

  // Customize markers
  // Set color by data intensity for location
  // Icon maker: https://icons8.com/icons/set/marker
  const customIcon = (intensity?: string) =>
    new Icon({
      iconUrl: `/icons/marker-${
        intensity === DataIntensity.High
          ? "green100"
          : intensity === DataIntensity.Low
          ? "green40"
          : // DataIntensity.Normal
            "green80"
      }.png`,
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      popupAnchor: [0, -20],
    });

  return (
    <Box>
      {/* Display chart title (full size map) */}

      <Box display="flex" justifyContent="center" my={1}>
        <Typography variant={"h4"}>Clips by Station Location</Typography>
      </Box>

      <Box height={mapHeight} >
        {/* <MapContainer zoom={13} style={{ height: "100vh", width: "100%" }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        </MapContainer> */}

        <MapContainer
          key={new Date().getTime()}
          center={mapDefaultCoordinates}
          zoom={mapDefaultZoom}
          zoomControl={true}
          scrollWheelZoom={true}
          className="leaflet-container"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {/* Map the markers from mapData */}
          {dataWithIntensity.map((dataPoint: any, i: number) =>
            dataPoint.lat && dataPoint.lng ? (
              <Marker
                key={i}
                icon={customIcon(dataPoint.intensity)}
                position={[dataPoint.lat, dataPoint.lng]}
              >
                <Popup className="leaflet-popup">
                  <Typography variant="body2" align="center">
                    <Box mb={0.5}> {dataPoint.name}</Box>
                    <Box> {dataPoint.count} clips</Box>
                  </Typography>{" "}
                </Popup>
              </Marker>
            ) : undefined
          )}
        </MapContainer>
      </Box>
    </Box>
  );
};

export default StationMap;
