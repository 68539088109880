// Query stale time
export const fiveMinutesStaleTime = 300000 // 5 min stale

// Leafletmap
export const mapHeight: number = 335;
export const mapDefaultCoordinates: [number, number] = [+20, 45];
export const mapDefaultZoom: number = 2;

// Upload Params
export const maxWidth = '1100px'
export const formWidth = '300px'

// Search bar width
export const searchBarWidth = "420px"