import { ReactNode, useMemo } from "react";
import Typography from "@mui/material/Typography";
import { Aggregation } from "../../Configs/interfaces";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

interface Props {
  filteredClips: {
    locKeywords: Aggregation;
    orgKeywords: Aggregation;
    perKeywords: Aggregation;
    miscKeywords: Aggregation;
  };
}

const NerViz = (props: Props) => {
  const locationArray = props.filteredClips.locKeywords.buckets.map(
    (location: { key: string; doc_count: number }) => (
      <div>
        {location.key} ({location.doc_count})
      </div>
    )
  );

  // Memoizing calculations
  const topOtherEntities = useMemo(() => {
    // Step 1: Consolidate keywords
    const allKeywords = (["orgKeywords", "miscKeywords", "perKeywords"] as const).flatMap(
      (key: keyof Props["filteredClips"]) =>
        props.filteredClips[key].buckets.map(({ key: keyword, doc_count }) => ({
          key: keyword,
          doc_count,
        }))
    );

    // Step 2: Sum Duplicates
    const summedKeywords = allKeywords.reduce(
      (acc: { [key: string]: { key: string; doc_count: number } }, { key, doc_count }) => {
        if (acc[key]) {
          acc[key].doc_count += doc_count;
        } else {
          acc[key] = { key, doc_count };
        }
        return acc;
      },
      {}
    );

    // Convert back to array and Step 3: Sort and Slice
    return Object.values(summedKeywords)
      .sort((a, b) => b.doc_count - a.doc_count)
      .slice(0, 25);
  }, [props.filteredClips]); // Dependency array

  return (
    <Grid container height={"100%"} columnSpacing={2}>
      <Grid xs={12} className="title">
        <Typography variant={"h4"} align="center">
          Entities Mentioned in Clips
        </Typography>
      </Grid>
      <Grid xs={6} overflow="hidden" height={"100%"} display="flex" flexDirection="column" pb={3}>
        <Box className="subtitle1 p10" >
          <Typography color="text.secondary">Locations</Typography>
        </Box>
        <Box height={"100%"} className="overflow-y-auto" flex={1}>
          {locationArray.map((location: ReactNode, index: number) => (
            <div key={index}>{location}</div>
          ))}
        </Box>
      </Grid>
      <Grid xs={6} overflow="hidden" height={"100%"} display="flex" flexDirection="column" pb={3}>
        <Box className="subtitle2 p10">
          <Typography color="text.secondary">Other Entities</Typography>
        </Box>

        <Box className="overflow-y-auto" height={"100%"} flex={1}>
          {topOtherEntities.map(
            ({ key, doc_count }: { key: string; doc_count: number }, index: number) => (
              <div key={index}>
                {key} ({doc_count})
              </div>
            )
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default NerViz;
