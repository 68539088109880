import { Box } from "@mui/material";

interface Props 
{isSmall?: boolean}
const Fallback = (props: Props) => {
  const loader = props.isSmall ? <div className="loader l-small" /> : <div className="loader l-normal" />;
  return (
    // <Box sx={{ gridColumn: '1 / -1' }}>
    <Box display='flex' justifyContent={'center'} width={'100%'}>
    
      {loader}
     </Box>
  );
};

export default Fallback;
