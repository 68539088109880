/////
// Set external environments
/////

// TODO
// Set all to prod when in prod

// POSTGRES environment
const setPG: "local" | "prod" | "dev" = "prod";

// AlertService API
const setAlertService: "local" | "prod" | "dev" = "prod";
// const setAlertService = "local";

// OPENSEARCH environment
const getOSData: "local" | "prod" = "prod";

// shareAlertsCard environment
const shareAlertsCard: "local" | "prod" = "prod";

/////
/////
/////
export function dev_v_prod() {
  const environment = {
    fqGet: "",
    fqPost: "",
    getOSData: "",
    getPresignedUrl: "",
    fqGetSignedURLs: "",
    shareAlertsCard: "",
  };

  // POSTGRES environment
  if (setPG === "prod") {
    environment.fqGet = "https://j9yejhkx1d.execute-api.us-east-1.amazonaws.com/fqGet";
    environment.fqPost = "https://j9yejhkx1d.execute-api.us-east-1.amazonaws.com/fqPost";
  } else if (setPG === "dev") {
    environment.fqGet = "https://ywrnnq4lwb.execute-api.us-east-1.amazonaws.com/fqGet";
    environment.fqPost = "https://ywrnnq4lwb.execute-api.us-east-1.amazonaws.com/fqPost";
  } else if (setPG === "local") {
    environment.fqGet = "http://localhost:3000/fqGet";
    environment.fqPost = "http://localhost:3000/fqPost";
  }

  // fq-API
  if (getOSData === "local") {
    environment.getOSData = "http://localhost:3000/getOSData";
    environment.getPresignedUrl = "http://localhost:3000/getPresignedUrl";
  } else if (getOSData === "prod") {
    environment.getOSData = "https://jmqyep0hba.execute-api.us-east-1.amazonaws.com/getOSData";
    environment.getPresignedUrl = "https://jmqyep0hba.execute-api.us-east-1.amazonaws.com/getPresignedUrl";
  }

  // AlertService API
  if (setAlertService === "local") {
    environment.fqGetSignedURLs = "http://localhost:9091/fqGetSignedURLs";
  } else if (setAlertService === "prod") {
    environment.fqGetSignedURLs =
      "https://0w89rz432f.execute-api.us-east-1.amazonaws.com/fqGetSignedURLs";
  }

  // shareAlertsCard React Route
  if (shareAlertsCard === "local") {
    environment.shareAlertsCard = "http://localhost:4280/alerts?";
  } else if (shareAlertsCard === "prod") {
    environment.shareAlertsCard = "http://frequency.rootwise.co/alerts?";
  }

  return environment;
}
