import { dev_v_prod } from "./dev_v_prod";

const environment = dev_v_prod();

export default async function getMaxPGID(table) {
  const response = await (
    await fetch(environment.fqPost, {
      method: "POST",
      body: JSON.stringify({
        getMaxID: true,
        table: table,
      }),
    })
  ).json();

  return response.response.rows[0].max;
}
