import React, { useState } from "react";

import { format } from "date-fns";

import Typography from "@mui/material/Typography";

import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

import { CardDetails } from "./CardDetails";
import { SaveCardComponent } from "../DataManagement/SavedCards/SaveCardComponent";

import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
import emojiFlags from "emoji-flags";

export default function CardHeader(props) {
  const [expandToggle, setExpandToggle] = useState(false);

  const [showDetailsStyle, setShowDetailsStyle] = useState("inline-div link m10 float-r text");

  // Convert epoch to local datetime
  let broadcastTimeInLocalZone = "";
  if (props.values._source.sourceMetadata !== undefined)
    broadcastTimeInLocalZone = format(
      new Date(props.values._source.sourceMetadata.epoch * 1000),
      "d MMMM yyyy @ HH:mm zz"
    );

  /////////////
  // Create card title: Job Name (text only) + FM Frequency
  /////////////

  let jobName = "";
  let programName = null;

  // Long fallback code block
  // Only run this if there is no station_name (and no program_name)
  if (!props.values?._source?.sourceMetadata?.stationName) {
    // Use regex to carve out just the text part of Job Name
    let suffixIndex = "";
    if (props.values._source.jobName.match(/\.[0-9]{8,}/)) {
      // Epoch time in jobName: "Stability Bulldog Wed.1660154479.part03"
      suffixIndex = props.values._source.jobName.search(/\.[0-9]{8,}/);
    } else if (props.values._source.jobName.match(/\.[0-9]{4}-[0-9]{2}-[0-9]{2}/)) {
      // Streamwriter format in jobName: "SAfm Sunrise.08-08-2022_01-00"
      suffixIndex = props.values._source.jobName.search(/\.[0-9]{4}-[0-9]{2}-[0-9]{2}/);
    }

    // Assign jobName
    jobName = props.values._source.sourceMetadata.name;
    if (suffixIndex === "") {
      jobName = props.values._source.jobName.split(".")[0];
    }
  } else {
    jobName = props.values._source.sourceMetadata["stationName"];
  }

  // Set programName if one is available
  if (props.values?._source?.sourceMetadata?.["program_name"] !== undefined) {
    programName = props.values._source.sourceMetadata["program_name"];
  }

  let cardTitle = jobName;
  if (props.values._source.sourceMetadata !== undefined) {
    if (props.values._source.sourceMetadata.frequency) {
      cardTitle += ` (${props.values._source.sourceMetadata.frequency} FM)`;
    } else {
      cardTitle += ` (online stream)`;
    }
  }

  // Toggle HitDetails on/off and button style
  const handleDetails = () => {
    setExpandToggle(!expandToggle);
    if (showDetailsStyle === "inline-div link float-r text") {
      setShowDetailsStyle("inline-div link m10 float-r contrast-text");
    } else {
      setShowDetailsStyle("inline-div link m10 float-r text");
    }
  };

  // Get country flag sourceMetadata
  polyfillCountryFlagEmojis();
  const countryFlag = emojiFlags.countryCode(
    props.values._source.sourceMetadata.stationCode.slice(0, 2)
  );

  return (
    <div>
    
      {props.showStar && <div className="inline-div link text m10 float-r">
        <SaveCardComponent values={props.values} />
      </div>}

      <Tooltip title="See Broadcast Details" placement="bottom" arrow>
      <div className={showDetailsStyle}>
        {<InfoIcon className="expand link" onClick={() => handleDetails()} />}
      </div>
      </Tooltip>

      {props.values._source.sourceMetadata.transmitterMap && (
        <Tooltip title="Transmitter Map" placement="bottom" arrow>
        <div className="inline-div m10 float-r">
          <a
            href={props.values._source.sourceMetadata.transmitterMap}
            className="link text"
            rel="noreferrer"
            target="_blank"
          >
            <TravelExploreIcon />
          </a>
        </div></Tooltip>
      )}

      <Typography component={'div'} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {broadcastTimeInLocalZone}
      </Typography>

      <Typography component={'div'} sx={{ fontSize: 15 }} color="text.primary" gutterBottom>
        {programName}
      </Typography>

      <Typography component={'div'} sx={{ mb: 1.5 }} color="text.primary">
        {cardTitle}{" "}
        <Tooltip title={countryFlag.name} placement="right">
          <div className="inline-div emoji-font">{countryFlag.emoji}</div>
        </Tooltip>
      </Typography>

      <Typography component={'div'} variant="body2">
        {expandToggle && <CardDetails values={props.values} />}
      </Typography>

      {/* <div className='inline-div link m30 arrow-r' onClick={() => translateClip(clipText)}><GTranslateIcon /></div> */}
    </div>
  );
}
