import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
// import Typography from "@mui/material/Typography";
import { dev_v_prod } from "../APICalls/dev_v_prod";
import { getProjectNames } from "../APICalls/getProjectNames";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// const axios = require("axios");

export const AddNewAlert = (props) => {
  const { user } = useAuth0();
  const [alertFrequency, setAlertFrequency] = useState(1440);
  const [alertProject, setAlertProject] = useState();
  const [alertLanguagePref, setAlertLanguagePref] = useState("en");
  const [submitted, setSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get project names from Postgres based on username
  useEffect(() => {
    const getProjects = async () => {
      const projectsRaw = await getProjectNames(user.name);
      setProjects(projectsRaw);
      setAlertProject(projectsRaw[0].project_id);
      setLoading(false);
    };
    if (user.name) getProjects();
  }, [user.name]);

  const handleNewAlertFrequency = (event) => {
    setAlertFrequency(event.target.value);
  };

  const handleNewAlertLanguagePref = (event) => {
    setAlertLanguagePref(event.target.value);
  };

  const handleNewAlertProject = (event) => {
    setAlertProject(event.target.value);
  };

  const handleNewAlertSubmit = async (event) => {
    event.preventDefault();

    // hide submit button, replace with 'in progress' text.
    setSubmitted(true);

    // Gather relevant values as an object
    const alertValues = {
      project: alertProject,
      query: event.target.alertQuery.value,
      frequency: event.target.alertFrequency.value,
      alertLanguagePref: event.target.alertLanguagePref.value,
      user_email: event.target.userEmail.value,
      other_emails: event.target.otherEmails.value,
    };

    if (event.target.alertName.value !== undefined) {
      alertValues.name = event.target.alertName.value;
    } else {
      alertValues.name = event.target.alertQuery.value;
    }

    try {

    // Get station data from Postgres
    // For all hits (including chart hits)
    const environment = dev_v_prod();
    const response = await fetch(environment.fqPost, {
      method: "POST",
      body: JSON.stringify({
        alerts: "addAlert",
        alertValues: alertValues,
      }),
    });

    if (response.status === 200) {
      setSnackbarOpen(true);

    }} catch (error) {
      setSnackbarErrorOpen(true);
    }
  };

  const submitState = () => {
    if (!submitted) {
      return (
        <div className="pb-5 centered-buttons">
          <Button type="submit" className="mt10" variant="contained">
            Set New Alert
          </Button>
        </div>
      );
    } else {
      return (
        <div className="pb-5 centered-buttons">
          <Button type="submit" className="mt10" variant="contained" disabled>
            Set New Alert
          </Button>
        </div>
      );
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  if (loading) return <div className="loader l-normal" />;

  return (
    <div className="add-new-alert">
      <form onSubmit={handleNewAlertSubmit}>
        <Table sx={{ minWidth: 650 }} aria-label="Existing Alerts">
          <TableBody>
            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="left" sx={{ fontSize: 16 }}>
                Project{" "}
              </TableCell>
              <TableCell align="left">
                <Select
                  name="alertProject"
                  size="small"
                  value={alertProject}
                  onChange={handleNewAlertProject}
                >
                  {projects.map((project) => (
                    <MenuItem key={project.project_id} value={project.project_id}>
                      {project.project_name}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>

            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="left" sx={{ fontSize: 16 }}>
                Alert name
              </TableCell>
              <TableCell align="left">
                <TextField
                  type="text"
                  size="small"
                  name="alertName"
                  className="search-box"
                  // value=""
                  // onChange={handleInputChange}
                  placeholder="Give this alert a name"
                />
              </TableCell>
            </TableRow>

            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="left" sx={{ fontSize: 16 }}>
                Alert query
              </TableCell>
              <TableCell align="left">
                <TextField
                  size="small"
                  type="text"
                  name="alertQuery"
                  className="search-box"
                  disabled
                  value={props.newAlertQuery}
                  // onChange={handleInputChange}
                  // placeholder={placeholderText}
                />
              </TableCell>
            </TableRow>

            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="left" sx={{ fontSize: 16 }}>
                Check for new alerts
              </TableCell>
              <TableCell align="left">
                <Select
                  size="small"
                  name="alertFrequency"
                  value={alertFrequency}
                  onChange={handleNewAlertFrequency}
                >
                  <MenuItem key={15} value={15}>
                    Every 15 Minutes
                  </MenuItem>
                  <MenuItem key={60} value={60}>
                    Every Hour
                  </MenuItem>
                  <MenuItem key={1440} value={1440}>
                    Once per Day
                  </MenuItem>
                  <MenuItem key={10080} value={10080}>
                    Once per Week
                  </MenuItem>
                </Select>
              </TableCell>
            </TableRow>

            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="left" sx={{ fontSize: 16 }}>
                Preferred Language for Alert Recipients
              </TableCell>
              <TableCell align="left">
                <Select
                  size="small"
                  name="alertLanguagePref"
                  value={alertLanguagePref}
                  onChange={handleNewAlertLanguagePref}
                >
                  <MenuItem key={"en"} value={"en"}>
                    English
                  </MenuItem>
                  <MenuItem key={"fr"} value={"fr"}>
                    French
                  </MenuItem>
                </Select>
              </TableCell>
            </TableRow>

            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="left" sx={{ fontSize: 16 }}>
                Send alert to
              </TableCell>
              <TableCell align="left">
                <TextField
                  size="small"
                  type="text"
                  name="userEmail"
                  className="search-box"
                  value={user.name}
                  disabled
                  // onChange={handleInputChange}
                  // placeholder="Optional. In addition to yourself, add other receipient emails seperated by a comma."
                />
              </TableCell>
            </TableRow>

            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="left" sx={{ fontSize: 16 }}>
                Other recipients
              </TableCell>
              <TableCell align="left">
                <TextField
                  size="small"
                  type="text"
                  name="otherEmails"
                  className="search-box"
                  // value={searchTerm}
                  // onChange={handleInputChange}
                  placeholder="Optional. Add other emails, seperated by a comma."
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
            Alert submitted!
          </Alert>
        </Snackbar>
        <Snackbar open={snackbarErrorOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: "100%" }}>
            Error submitting alert!
          </Alert>
        </Snackbar>

        <div className="mt10">{submitState()}</div>
      </form>
    </div>
  );
};
