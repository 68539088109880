import { dev_v_prod } from "../../APICalls/dev_v_prod";

const environment = dev_v_prod();

export default async function handleSaveCard(action, card, project_id, username, searchTerm) {
  // This function either saves or deletes a card from the project/user's save list
  // The save list is stored in PostGres saved_cards table
  // The action param is a boolean determining whether to save or delete the card

  // CHECKPOINT... dont go to opensearch
  // go to PG, drop project_id, username, indexID, highlightArray

  if (action) {
    await (
      await fetch(environment.fqPost, {
        method: "POST",
        body: JSON.stringify({
          handleSaveCard: action,
          project_id: project_id,
          username: username,
          indexID: card.indexID,
          highlightArray: card.highlightArray,
          searchTerm: searchTerm,
        }),
      })
    ).json();
  }
}
