import * as React from "react";
// import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { AddNewAlert } from "./AddNewAlert";
import ShowExistingAlerts from "./ShowExistingAlerts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography>
          <Typography component={'span'}>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ maxWidth: "1100px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider"}}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Alerts Tab"
        >
          <Tab label="New Alert" {...a11yProps(0)} />
          <Tab label="Existing Alerts" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} >

        <AddNewAlert newAlertQuery={props.newAlertQuery} />
     
      </TabPanel>
      <TabPanel value={value} index={1} sx={{ minWidth: 350, height: '100' }}>
        <ShowExistingAlerts />
      </TabPanel>
    </Box>
  );
}
