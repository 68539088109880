// Global
import { useEffect, useState, useContext } from "react";

// Local
import { CardArray } from "../Components/BroadcastCards/CardArray";
import { useClips } from "../Hooks/useClips";
import { formatFilters } from "../Components/DataManagement/DataCleaning/formatFilters";
import { FilterControls } from "../Hooks/useFilters";
import {
  PageLoadContext,
  SearchTermContext,
  DateObjContext,
} from "../Components/DataManagement/InitContexts";
import NerViz from "../Components/Charts/NerViz";
import { Box, Stack, Typography } from "@mui/material";
import Subheader from "../Components/Navigation/Subheader";
import StationMap from "../Components/Charts/StationMap";
import LineChart from "../Components/Charts/LineChart";
import useSavedCards from "../Hooks/useSavedCards";
import FqCard from "../Components/Charts/FqCard";

interface Props {
  filterControls: FilterControls;
}

// Show the clips and charts
const ViewClips = (props: Props) => {
  const pageLoadData = useContext(PageLoadContext);
  const searchTerm = useContext(SearchTermContext);
  const dateObj = useContext(DateObjContext);

  // Local

  // const [isLoading, setIsLoading] = useState(true);
  const [isOldestFirst, setIsOldestFirst] = useState<boolean>(false);

  // This block extracts the Opensearch IDs of saved cards
  const { savedCardArray } = useSavedCards({ pageLoadData, searchTerm });

  // Get clips data from opensearch
  const { clipData, fetchControls, resetFilters } = useClips({
    pageLoadData,
    searchTerm,
    dateObj,
    isOldestFirst,
    filters: props.filterControls.filters,
    setNewFilterOptions: props.filterControls.setNewFilterOptions,
    savedCardArray,
  });

  // Set filter options based on returned data
  useEffect(() => {
    if (
      clipData?.aggregations &&
      props.filterControls.filterOptions === undefined &&
      resetFilters > 0
    ) {
      props.filterControls.setNewFilterOptions(
        formatFilters(clipData.aggregations?.stationAggs, pageLoadData.stations)
      );
    }
  }, [clipData.aggregations, props.filterControls.filters, pageLoadData.stations, resetFilters]);

  return (
    <Stack display="flex" height={"100%"} width={1} overflow={"hidden"}>
      <Subheader
        clipData={clipData}
        isOldestFirst={isOldestFirst}
        handleIsOldestFirst={setIsOldestFirst}
        filterControls={props.filterControls}
      />
      <Stack display="flex" direction="row" overflow="hidden" spacing={4} mt={1} mb={4}>
        <CardArray clipData={clipData} fetchControls={fetchControls} />

        {/* Show Charts (if search is not saved cards, newest broadcasts) */}
        {searchTerm !== "GETSAVEDCARDS" && searchTerm !== "GETLAST10" && (
          <Box sx={{ overflowY: "auto" }} pr={0.5}>
            {/* Map */}
            {clipData.aggregations?.stationAggs && (
              <FqCard>
                <StationMap
                  stationAggs={clipData.aggregations.stationAggs}
                  stations={pageLoadData.stations}
                />
              </FqCard>
            )}

            {searchTerm !== "GETLAST10" &&
              searchTerm !== "GETSAVEDCARDS" &&
              clipData.aggregations?.nlpAggs && (
                <div className="">
                  <FqCard>
                    <NerViz filteredClips={clipData.aggregations.nlpAggs.filteredClips} />
                  </FqCard>
                </div>
              )}

            {clipData.aggregations?.evidenceByDate && (
              <FqCard isShort>
                <Box display="flex" justifyContent="center" my={1}>
                  <Typography variant={"h4"}>Number of Clips by Day</Typography>
                </Box>
                <LineChart
                  evidenceByDate={clipData.aggregations.evidenceByDate}
                  startDateRange={dateObj.startDateRange}
                  endDateRange={dateObj.endDateRange}
                />
              </FqCard>
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default ViewClips;
