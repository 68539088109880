import React, { useState } from "react";
import ReactHtmlParser from "html-react-parser";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

import { modalStyle } from "../../Styles/modalStyle";
import CardHeader from "./CardHeader";
import getHighlightTimestamp from "./getHighlightTimestamp";
import getPlaybackLink from "./getPlaybackLink";
import removeHTMLTags from "../DataManagement/DataCleaning/removeHTMLTags";
import langCodeLookup from "../DataManagement/DataCleaning/langCodeLookup";
import ModalClose from "../Navigation/ModalClose";

// This function appears as a drilldown from highlight but actually goes UP to clip level
// This gives the user the context surrounding the clip they want more info on
// It's a modal browser showing the clip and surrounding clips from which the highlight
// Was derived
// It borrows styling and code from highlight.js.  Should be abstracted where possible.
export default function HighlightDrilldown(props) {
  // In this function is where you should modify the current clip
  // To have the <strong> tags that match the highlight
  const [currentClipOffset, setCurrentClipOffset] = useState(props.highlight.clipOffset);

  // Get the clipText in the language (original/translation) of the highlight the user selected
  // Include the original highlight bolding in the clipText navigation modal

  const clipLangLookUp = props.highlight.translated ? props.highlight.language : "original";

  const cleanHighlight = removeHTMLTags(props.highlight.text);
  const clipTextBolded = props.values._source.clips[props.highlight.clipOffset].clip[
    clipLangLookUp
  ].replace(cleanHighlight, props.highlight.text);
  // Modify props.values to include the new bolded content
  const newPropsValues = props.values;
  newPropsValues._source.clips[props.highlight.clipOffset].clip[clipLangLookUp] = clipTextBolded;

  // User iterates on clipOffset
  const handleButton = (delta) => {
    setCurrentClipOffset(currentClipOffset + delta);
  };

  return (
    <div>
      <Modal open={props.showHighlightDrilldown} onClose={props.handleHighlightDrilldown}>
        <Box sx={modalStyle}>
          <ModalClose onClose={props.handleHighlightDrilldown} />
          <div className="highlight-drilldown-modal">
            <div className="highlight-drilldown-card">
              <DrilldownCard
                values={newPropsValues}
                highlight={props.highlight}
                currentClipOffset={currentClipOffset}
              />
            </div>
            <div className="highlight-drilldown-button-row">
              <Button onClick={() => handleButton(-1)} disabled={currentClipOffset === 0}>
                Previous Clip
              </Button>
              {currentClipOffset + 1} of {props.values._source.clips.length}
              <Button
                onClick={() => handleButton(1)}
                disabled={currentClipOffset === props.values._source.clips.length - 1}
              >
                Next Clip
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

function DrilldownCard(props) {
  const [showPlayer, setShowPlayer] = useState("");
  const [playButtonStyle, setPlayButtonStyle] = useState("inline-div link m5 text");
  const [audioURL, setAudioURL] = useState("");
  const [currentClipLang, setCurrentClipLang] = useState(
    props.highlight.translated ? props.highlight.language : "original"
  );

  // Get the Card Header from component (station name, broadcast time, etc)
  const clipCardHeader = <CardHeader values={props.values} showStar={false} />;

  // Reference to the current clip
  const currentClip = props.values._source.clips[props.currentClipOffset];

  // Get currentClip playback information
  const clipTimestamp = getHighlightTimestamp(currentClip.startTime, currentClip.endTime);
  const playbackTimestamps = [currentClip.startTime, currentClip.endTime];

  // Get the clipText in the language (original/translation) of the highlight the user selected
  // const clipLangLookUp = props.highlight.translated ? props.highlight.language : "original";
  let clipText = currentClip.clip[currentClipLang];

  // Get language options
  const clipLangOptions = Object.keys(currentClip.clip);
  clipLangOptions[0] =
    "original (" +
    langCodeLookup(props.values._source.sourceMetadata.language_code.slice(0, 2)) +
    ")";

  const handleSetCurrentLang = (lang) => {
    if (lang === clipLangOptions[0]) lang = "original";
    setCurrentClipLang(lang);
  };

  const clipLangUI = clipLangOptions.map((lang, index) => (
    <Button
      key={index}
      onClick={() => handleSetCurrentLang(lang)}
      disabled={lang.slice(0, 8) === currentClipLang}
    >
      <Typography
        sx={{
          color: lang.slice(0, 8) === currentClipLang ? "#0099ff" : null,
          fontWeight: 500,
          // fontSize: 0.875,
          lineHeight: 1.75,
          letterSpacing: 0.02857,
          textTransform: "uppercase",
        }}
      >
        {" "}
        {langCodeLookup(lang)}
      </Typography>
    </Button>
  ));

  // Function to return the playback audio URL and player
  const playhighlight = async () => {
    // Style button
    if (playButtonStyle === "inline-div link m5 text") {
      setPlayButtonStyle("inline-div link m5 contrast-text");
    } else {
      setPlayButtonStyle("inline-div link m5 text");
    }

    if (!showPlayer) {
      // Call function getPlayBacketails to retrieve highlight
      let fullAudioURL = "";

      // Get precision playback audio
      fullAudioURL = await getPlaybackLink(
        props.values._source.sourceMetadata.deviceId,
        props.values._source.jobName,
        props.values._source.sourceMetadata.keyDatePath,
        playbackTimestamps,
        props.values._source.audioType
      );

      setAudioURL(fullAudioURL);

      // When function returns, reveal the player in the UI
      setShowPlayer(true);
    } else {
      // If player is already visible, hide it
      setShowPlayer(false);
    }
  };

  // Draw the playback button
  const clipActions = (
    <div>
      <div className={playButtonStyle} onClick={playhighlight}>
        <PlayCircleOutlineIcon />
      </div>
    </div>
  );

  return (
    <div className="inline-div drilldown-card">
      {/* <Card sx={{ minWidth: 275, display: "grid", gridTemplateColumns: columns }}> */}
      {/* <CardContent> */}
      {clipCardHeader}
      {clipLangOptions.length > 1 && clipLangUI}
      <div className="drilldown-highlight-div">
        <div>{clipActions}</div>
        <div className="highlight-drilldown-text">
          <div>
            {showPlayer && <audio controls autoPlay src={audioURL} controlsList="nodownload" />}
          </div>

          <div className="text-secondary">{clipTimestamp}</div>

          <Typography variant="body2">{ReactHtmlParser(clipText)}</Typography>
        </div>
      </div>
      {/* </CardContent> */}
      {/* </Card> */}
    </div>
  );
}
