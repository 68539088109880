// Global
import axios, { AxiosResponse } from "axios";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";

// Local
import { dev_v_prod } from "../Components/APICalls/dev_v_prod";
import { pageLoadDataQuery } from "../Configs/keyConstants";
import { Station } from "../Models/station";

//
// This hook retrieves data about the user
// Language preferences, allowable projects and stations
// It's designed to run once on page load
//

interface QueryProps {
  queryKey: [string, { user?: string }];
}

const getPageLoadData = async ({ queryKey }: QueryProps) => {
  const [_key, { user }] = queryKey;
  // Get Project IDs and Station Codes from Postgres
  const environment = dev_v_prod();

  // Get Project IDs, Names, Language Pref, and Station Codes
  const projectData = await axios.post(environment.fqPost, {
    getPageLoadData: true,
    username: user,
  });

  // Iterate over each row and add the project IDs and names to the Sets
  const projectIdsSet = new Set<number>();
  projectData.data.response.rows.forEach((row: any) => {
    row.project_ids.forEach((id: number) => projectIdsSet.add(id));
  });
  // Convert the Sets to Arrays and sort them
  const ids = Array.from(projectIdsSet).sort((a, b) => a - b);

  // Get prefabQueries
  const user_id = projectData.data.response.rows[0].user_id;
  const [prefabQueryData, prefabWhitelistData, prefabBlacklistData] = await Promise.all([
    axios.get(`${environment.fqGet}?projectIDs=${ids.join(",")}`),
    axios.get(`${environment.fqGet}?queryWhitelist=true&user_id=${user_id}`),
    axios.get(`${environment.fqGet}?queryBlacklist=true&user_id=${user_id}`),
  ]);

  return { projectData, prefabQueryData, prefabWhitelistData, prefabBlacklistData };
};

// Parse Results
const parseResults = (
  data: {
    projectData: AxiosResponse;
    prefabQueryData: AxiosResponse;
    prefabWhitelistData: AxiosResponse;
    prefabBlacklistData: AxiosResponse;
  },
  username?: string
) => {
  const { projectData, prefabQueryData, prefabWhitelistData, prefabBlacklistData } = data;

  const stations: Station[] = projectData.data.response.rows.map((row: any) => new Station(row));

  // Create a Set to store unique project IDs and names
  const projectIdsSet = new Set<number>();
  const projectNamesSet = new Set<string>();

  // Iterate over each row and add the project IDs and names to the Sets
  projectData.data.response.rows.forEach((row: any) => {
    row.project_ids.forEach((id: number) => projectIdsSet.add(id));
    row.project_names.forEach((name: string) => projectNamesSet.add(name));
  });

  // Convert the Sets to Arrays and sort them
  const projects = {
    ids: Array.from(projectIdsSet).sort((a, b) => a - b),
    names: Array.from(projectNamesSet).sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    ),
  };

  // Set user preferences Postgres
  const language_pref: string = projectData.data.response.rows[0].language_pref;
  const user_id: number = projectData.data.response.rows[0].user_id;

  // Set prefabQueries
  let prefabQueries = prefabQueryData.data.response;
  const prefabWhitelist = prefabWhitelistData.data.response;
  const prefabBlacklist = prefabBlacklistData.data.response;

  // Filter out the prefabQueries that are in the prefabBlacklist
  prefabQueries = prefabQueries.filter(
    (query: any) =>
      !prefabBlacklist.some((blacklistItem: any) => blacklistItem.query_id === query.query_id)
  );

  // First, ensure prefabWhitelist queries are included, deduplicating based on query_id
  const uniqueQueryIds = new Set(prefabQueries.map((query: any) => query.query_id));
  const whitelistedQueriesToAdd = prefabWhitelist.filter(
    (whitelistItem: any) => !uniqueQueryIds.has(whitelistItem.query_id)
  );
  prefabQueries = [...prefabQueries, ...whitelistedQueriesToAdd];

  // Sort prefabQueries by display_order in ascending order
  prefabQueries.sort((a: any, b: any) => a.display_order - b.display_order);

  // Check if the special prefab queries already exist
  const hasNewestBroadcasts = prefabQueries.find((query: any) => query.query_id === 100000);
  const hasShowSavedClips = prefabQueries.find((query: any) => query.query_id === 100001);

  // Add hard-coded special prefab queries only if they don't already exist
  if (!hasNewestBroadcasts) {
    prefabQueries.push({
      query_id: 100000,
      query_name: "Newest Broadcasts",
      query: "GETLAST10",
      display_order: 100000,
    });
  }

  if (!hasShowSavedClips) {
    prefabQueries.push({
      query_id: 100001,
      query_name: "Show Saved Clips",
      query: "GETSAVEDCARDS",
      display_order: 100001,
    });
  }

  return { stations, projects, user_id, language_pref, username, prefabQueries };
};

// Query
export const usePageLoadData = () => {
  const { user } = useAuth0();
  const { data } = useSuspenseQuery({
    queryKey: [pageLoadDataQuery, { user: user?.name }],
    queryFn: getPageLoadData,
    select: (data) => parseResults(data, user?.name),
    staleTime: Infinity,
  });

  // console.log("pageLoadData:", data);

  return { pageLoadData: data };
};
