import { useState, useEffect, useContext } from "react";
import { useMutation } from "@tanstack/react-query";

// Calendar imports
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Modal from "@mui/material/Modal";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// Materials UI
import StarIcon from "@mui/icons-material/Star";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ListIcon from "@mui/icons-material/List";
import EditIcon from "@mui/icons-material/Edit";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, Snackbar, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
// import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import Tooltip from "@mui/material/Tooltip";

// Internal imports
import { modalStyle } from "../../Styles/modalStyle";
import { UserOptions } from "../UserOptions";
import { AlertsModal } from "../Alerts/AlertsModal";
import SaveSearchDialog from "./SaveSearchDialog";
import { PrefabSearch } from "./PrefabSearch";
import { CalendarUI } from "./CalendarUI";
import { SearchHelpGuide } from "../StaticAssets/SearchTermHelpGuide";
// import { RadioButton } from "../FormElements/RadioButton";
import ModalClose from "./ModalClose";
import { PageLoadContext, DateObjContext } from "../DataManagement/InitContexts";
import { searchBarWidth } from "../../Configs/configs";
import updateQueries from "../DataManagement/updateQueries";
import Fallback from "./Fallback";
import useQueryInvalidation from "../../Hooks/useQueryInvalidation";
import { pageLoadDataQuery } from "../../Configs/keyConstants";

interface Props {
  onSearchSubmit: (value: string) => void;
  // disabled: boolean;
}

// App header and search bar
export const Navbar = (props: Props) => {
  const pageLoadData = useContext(PageLoadContext);
  const dateObj = useContext(DateObjContext);

  const [isCustomSearch, setIsCustomSearch] = useState<boolean>(false);
  const [customSearchTerm, setCustomSearchTerm] = useState("");
  const [prefabTerm, setPrefabTerm] = useState("");

  const [showSearchHelp, setShowSearchHelp] = useState<boolean>(false);
  const [showSaveSearchDialog, setShowSaveSearchDialog] = useState(false);
  const [showFallback, setShowFallback] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);
  const [calStyle, setCalStyle] = useState("link calendar-icon");
  const [newDateRange, setNewDateRange] = useState();

  const [showAlerts, setShowAlerts] = useState<boolean>(false);

  // Set initial prefab term in window
  useEffect(() => {
    if (prefabTerm === "" && pageLoadData?.prefabQueries && pageLoadData.prefabQueries.length > 0) {
      setPrefabTerm(pageLoadData.prefabQueries[0].query);
      setCustomSearchTerm(pageLoadData.prefabQueries[0].query);
    }
  }, [pageLoadData]);

  // handleSubmit changes searchTermSubmitted
  const handleSubmit = (event: any) => {
    event.preventDefault();
    props.onSearchSubmit(customSearchTerm);
  };

  // Set Search bar value to user input
  const handleInputChange = (event: any) => {
    setCustomSearchTerm(event.target.value);
  };

  // Toggle search type
  const handleToggle = (value: boolean) => {
    // Turn on custom search
    if (!value) {
      props.onSearchSubmit(customSearchTerm);
      setIsCustomSearch(true);
    }

    // Turn on prefab query
    else {
      setIsCustomSearch(false);
      setPrefabTerm(prefabTerm);
    }
  };

  // If users selects a new prefab query, execute search
  const handlePrefabSelection = (prefabTerm: string) => {
    setCustomSearchTerm(prefabTerm);
    setPrefabTerm(prefabTerm);
    props.onSearchSubmit(prefabTerm);
  };

  // This function executes if user clicks the "Star" icon
  // Retrieves saved clips
  const handleGetSavedCards = () => {
    // handlePrefabSearch("GETSAVEDCARDS");
    handleToggle(false);
    props.onSearchSubmit("GETSAVEDCARDS");
  };

  /////////////
  // Calendar functions
  /////////////

  const handleShowCalendar = (setDate: boolean) => {
    setShowCalendar(!showCalendar);

    // Open calendar
    if (!showCalendar) {
      setCalStyle("link m10 calendar-icon-active");
      setShowCalendar(true);
    }

    // Close calendar
    else {
      setShowCalendar(false);
      setCalStyle("link m10 calendar-icon");
      if (setDate) {
        dateObj.handleDateRange(newDateRange);
      }
    }
  };

  // Lift this right up to handleDateRange in App.js
  const handleDateRange = (event: any) => {
    setNewDateRange(event);
  };

  const calendarModal = (
    <div>
      <Modal open={showCalendar} onClose={() => handleShowCalendar(false)}>
        <Box sx={modalStyle}>
          <ModalClose onClose={() => handleShowCalendar(false)} />
          <CalendarUI
            dateRange={handleDateRange}
            startDateRange={dateObj.startDateRange}
            endDateRange={dateObj.endDateRange}
            handleShowCalendar={handleShowCalendar}
          />
        </Box>
      </Modal>
    </div>
  );

  /////////////
  // Mutate functions for create/update/delete prefab Queries
  /////////////
  // Invalidate pageLoadData (with queries)
  const { invalidate } = useQueryInvalidation(pageLoadDataQuery);

  // Mutation workflow for file upload
  const mutation = useMutation({
    mutationFn: updateQueries,
    onMutate: () => {
      setShowFallback(true);
    },
    onSuccess: () => {
      setSnackbarOpen(true);
    },
    onError: (error: Error) => {
      setSnackbarErrorOpen(true);
    },
    onSettled: () => {
      setShowFallback(false);
      invalidate();
    },
  });

  // Close snack bar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarErrorOpen(false);
  };

  /////////////
  // Other NavBar functions
  /////////////

  const handleAlertsModal = () => {
    setShowAlerts(!showAlerts);
  };

  // Frequency Logo
  const logo = (
    <a href="https://frequency.rootwise.co">
      <img
        src="https://ampmedia.blob.core.windows.net/global-files/RootWise Frequency (transparent).png"
        alt="RootWise Logo"
        className="fq-logo"
      />
    </a>
  );

  return showFallback ? (
    <Fallback />
  ) : (
    <div className="navbar">
      <div className="inline-div grid-logo">{logo}</div>

      <div className="inline-div grid-search">
        {/* Search bar */}
        <form onSubmit={handleSubmit}>
          {/* <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}> */}
          <Stack
            height="100%"
            direction="row"
            justifyContent="left"
            alignItems={"center"}
            spacing={1}
          >
            <ToggleButtonGroup
              value={isCustomSearch}
              exclusive
              onChange={() => setIsCustomSearch(!isCustomSearch)}
              aria-label="text alignment"
              size="small"
            >
              <Tooltip title="Select a Topic">
                <ToggleButton value={false} aria-label="prefab">
                  <ListIcon color="primary" />
                </ToggleButton>
              </Tooltip>
              <Tooltip title="Custom Search">
                <ToggleButton value={true} aria-label="custom">
                  <EditIcon color="primary" />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>

            {/* If customerSearch = false, show prefab search queries in drop-down */}
            {!isCustomSearch && pageLoadData?.prefabQueries !== undefined && (
              <PrefabSearch
                handlePrefabSelection={handlePrefabSelection}
                prefabQueries={pageLoadData?.prefabQueries}
                prefabTerm={prefabTerm}
                pageLoadData={pageLoadData}
                mutation={mutation}
              />
            )}

            {/* If customSearch = true, show search box */}
            {isCustomSearch && (
              <TextField
                placeholder="Search"
                id="custom-search-bar"
                variant="outlined"
                className="search-box"
                size="small"
                value={customSearchTerm}
                onChange={handleInputChange}
                // disabled={props.disabled}
                sx={{ maxWidth: searchBarWidth, width: "100%", backgroundColor: "#FFF" }}
                multiline={true}
                maxRows={2}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box display="flex" onClick={() => setShowSearchHelp(!showSearchHelp)}>
                        <Tooltip title="Search Help">
                          <HelpOutlineIcon className="link" />
                        </Tooltip>
                      </Box>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box display="flex" onClick={() => setShowSaveSearchDialog(true)}>
                        <Tooltip title="Save Search">
                          <SaveIcon className="link" />
                        </Tooltip>
                      </Box>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault(); // Prevent form submission if inside a form
                    handleSubmit(event);
                  }
                }}
              />
              /* <input type="text" /> */
            )}
            <Tooltip title="Select date range" placement="bottom" arrow>
              <CalendarMonthIcon
                className={calStyle}
                fontSize="large"
                onClick={() => handleShowCalendar(false)}
              />
            </Tooltip>

            {isCustomSearch && (
              <Tooltip title="Search" placement="bottom" arrow>
                <SearchIcon
                  className="link"
                  color="primary"
                  fontSize="large"
                  onClick={handleSubmit}
                />
              </Tooltip>
            )}
          </Stack>
        </form>
      </div>

      <Stack direction="row" alignItems="center" spacing={1} ml={4}>
        <Tooltip title="View Saved Clips" placement="bottom" arrow>
          <StarIcon
            className="link"
            fontSize="large"
            color="primary"
            onClick={handleGetSavedCards}
          />
        </Tooltip>
        <Tooltip title="View and Create Alerts" placement="bottom" arrow>
          <EditNotificationsIcon
            className="link"
            fontSize="large"
            onClick={handleAlertsModal}
            color="primary"
          />
        </Tooltip>
        <UserOptions />
      </Stack>

      {/* MODALS */}

      {calendarModal}

      <AlertsModal
        showAlerts={showAlerts}
        handleAlertsModal={handleAlertsModal}
        newAlertQuery={isCustomSearch ? customSearchTerm : prefabTerm}
      />

      {showSearchHelp && <SearchHelpGuide setShowSearchHelp={setShowSearchHelp} />}

      {showSaveSearchDialog && (
        <SaveSearchDialog
          open={showSaveSearchDialog}
          customSearchTerm={customSearchTerm}
          onClose={() => setShowSaveSearchDialog(false)}
          pageLoadData={pageLoadData}
          mutation={mutation}
        />
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Alert submitted!
        </Alert>
      </Snackbar>

      <Snackbar open={snackbarErrorOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert severity="error" sx={{ width: "100%" }}>
          Error submitting alert!
        </Alert>
      </Snackbar>
    </div>
  );
};
