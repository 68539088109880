import { Aggregation } from "../../../Configs/interfaces";
import { Station } from "../../../Models/station";
import { FilterProps } from "../../../Hooks/useFilters";

// Extract Filter Data Options from clipData hits
export const formatFilters = (stationAggs: Aggregation, stations: Station[]) => {

  if (!stationAggs?.buckets || stationAggs.buckets.length === 0) {
    return [];
  }

  const optionsMap = new Map();

  stationAggs.buckets.forEach((stationFromAgg) => {
    const stationMatch = stations.find((station) => station.stationCode === stationFromAgg.key);
    if (stationMatch) {
      const value = stationFromAgg.key;
      if (!optionsMap.has(value)) {
        optionsMap.set(value, {
          country: stationMatch.country,
          label: stationMatch.stationName,
          value: value,
        });
      }
    }
  });

  const uniqueFilterOptions = Array.from(optionsMap.values()).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  return uniqueFilterOptions;
};

// Filter station codes by all possible filters
export const mergeStationCodeFilters = (stations: Station[], filters: FilterProps | undefined) => {
  // Filter station codes by all possible filters
  let stationCodes = stations.map((station) => station.stationCode);
  if (filters?.stations) {
    stationCodes = stationCodes.filter((station) =>
      filters?.stations?.some((filterItem) => station === filterItem.value)
    );
  }
  if (filters?.countries) {
    stationCodes = stationCodes.filter((station) =>
      filters?.countries?.some((filterItem) => station.startsWith(filterItem.value))
    );
  }
  return stationCodes;
};
