import { useState, useContext, useEffect } from "react";

import handleSaveCard from "./handleSaveCard";
import DialogBox from "../../Navigation/DialogBox";
import { RadioButton } from "../../FormElements/RadioButton";
import getMaxPGID from "../../APICalls/getMaxPGID";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { Tooltip } from "@mui/material";

import { SearchTermContext, PageLoadContext } from "../InitContexts";
import handleDeleteSavedCard from "./handleDeleteSavedCard";

/////
// This set of functions allows user to save cards
/////

export const SaveCardComponent = (props) => {
  const searchTerm = useContext(SearchTermContext);
  const pageLoadData = useContext(PageLoadContext);


  const [isSaved, setIsSaved] = useState(() => {
    if (searchTerm === "GETSAVEDCARDS") {
      return true;
    }
    return false;
  });
  const [showChooseProject, setShowChooseProject] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [chosenProject, setChosenProject] = useState(pageLoadData.projects.ids[0]);
  const [projects, setProjects] = useState();

  useEffect(()=> {
    if (pageLoadData.projects.ids && !projects) {
      setProjects(pageLoadData.projects)
    }
  }, [pageLoadData.projects])

  const handleChooseID = (id) => {
    setChosenProject(id);
  };

  // Show dialogue box for users with >1 project
  const chooseProjectParams = {
    title: "Choose Project",
    description: (
      <form>
        Choose the project to which the clip will be saved:
        {projects?.ids && projects.ids.map((id, i) => (
          <div key={id}>
            <RadioButton
              label={projects.names[i]}
              value={chosenProject === id}
              onChange={() => handleChooseID(id)}
              // disabled={false}
            />
          </div>
        ))}
      </form>
    ),
    buttons: [
      { variant: "text", proceed: false, text: "Cancel" },
      { variant: "contained", proceed: true, text: "Select Project" },
    ],
  };

  const confirmDeleteParams = {
    title: "Are you sure?",
    description: (
      <div>
        <div>Remove this card from the saved list?</div>
        <div>It was added to the list by {pageLoadData.username}</div>
      </div>
    ),
    buttons: [
      { variant: "text", proceed: false, text: "Cancel" },
      { variant: "contained", proceed: true, text: "Remove" },
    ],
  };
  // common path just insta-saves.
  // >1 project users have to affirmatively submit on the dialogue box
  const handleSaveHit = async () => {
    // Set newSave = true if user is adding to Saved Clips.  false if removing (deleting) a saved clip.

    if (!isSaved) {
      // // Pathway to SAVE card
      if (pageLoadData.projects.ids.length > 1) {
      //   // Get project names
      //   const projects = await getProjectNames(pageLoadData.username);
      //   const projNames = [];
      //   const projIDs = [];
      //   for (const project of projects) {
      //     projNames.push(project.project_name);
      //     projIDs.push(project.project_id);
      //   }
      //   setProjects({
      //     projectNames: projNames,
      //     projectIDs: projIDs,
      //   });

        // Show dialogue for multiple user projects
        // Populate with projects info from above
        setShowChooseProject(true);
      } else {
        // Auto-execute if only one project available to user
        handleSaveProceed();
      }
    } else {
      // Pathway to UNSAVE card
      setShowConfirmDelete(true);
    }
  };

  // dialogue box close-out function: SAVE card
  const handleSaveProceed = async () => {
    const correctSearchTerm =
      searchTerm !== "GETSAVEDCARDS"
        ? searchTerm
        : props.values._source.sourceMetadata.saved_searchTerm;
    await handleSaveCard(
      true,
      props.values,
      chosenProject,
      pageLoadData.username,
      correctSearchTerm
    );

    // This block ensures that if a user RE-saves the card, the pgID is updated
    // This allows the user to toggle infinitely between saving/unsaving card
    if (searchTerm === "GETSAVEDCARDS") {
      const maxID = await getMaxPGID("saved_cards");
      props.values._source.sourceMetadata.saved_pgID = maxID;
    }

    // Fill in star indicator
    setIsSaved(true);
    setShowChooseProject(false);
  };

  // dialogue box close-out function: DELETE card
  const handleDeleteProceed = async () => {
    await handleDeleteSavedCard(props.values._source.sourceMetadata.saved_pgID);
    // Fill in star indicator
    setIsSaved(false);
    setShowConfirmDelete(false);
  };

  return (
    <div>
      {!isSaved && <Tooltip title="Save" placement="bottom" arrow><StarBorderIcon onClick={handleSaveHit}/></Tooltip> }
      {isSaved && <Tooltip title="Unsave" placement="bottom" arrow><StarIcon onClick={handleSaveHit}/></Tooltip> }

      {showChooseProject && (
        <DialogBox params={chooseProjectParams} handleProceed={handleSaveProceed} />
      )}
      {showConfirmDelete && (
        <DialogBox params={confirmDeleteParams} handleProceed={handleDeleteProceed} />
      )}
    </div>
  );
};
