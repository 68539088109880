import React from "react";

export const RadioButton = ({ label, value, onChange, disabled }) => {
  return (
    <label>
        <input type="radio" checked={value} onChange={onChange} disabled={disabled} />
        {label}
    </label>
  );
};
