
export default function getHighlightTimestamp(startTime, endTime ) {
  // Build the Timestamp visual above each highlight
  let highlightTimestamp = "";
  if (startTime) {
    const startMin = Math.floor(parseFloat(startTime) / 60);
    const startSec = Math.round(startTime % 60).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    highlightTimestamp = startMin.toString() + ":" + startSec.toString();

    if (endTime) {
      const endMin = Math.floor(parseFloat(endTime) / 60);
      const endSec = Math.round(endTime % 60).toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      highlightTimestamp += " - " + endMin.toString() + ":" + endSec.toString();
    }
  }

  return highlightTimestamp;
}

