import getAlertCards from "./getAlertCards";
import { dev_v_prod } from "../Components/APICalls/dev_v_prod";
import { addMetadata } from "../Components/DataManagement/addMetadata";
import { fetchPostgresMetadata } from "../Components/APICalls/getPostgresMetadata";
import axios from "axios";
import { Station } from "../Models/station";

export default async function loadAlertCards() {
  // Get the alert_cards data from PG
  // Based on the query string of the URL
  const urlParams = new URLSearchParams(window.location.search);
  const constructIds = [];
  let autoplayIndex;

  for (const [key, value] of urlParams.entries()) {
    // console.log(`${key}: ${value}`);
    if (key === "id") {
      const splitIds = value.split(",");
      for (const splitId of splitIds) {
        constructIds.push(splitId);
      }
    } else if (key === "highlight") {
      autoplayIndex = parseInt(value);
    }
  }
  const savedAlertCards = await getAlertCards(constructIds);

  // Parse the opensearch IDs of the alertCards
  const opensearch_id_array = [];
  for (const card of savedAlertCards) {
    opensearch_id_array.push(card.opensearch_id);
  }

  // Build a query string for getHitData
  const params = {
    searchType: "getSavedCards",
    searchTerm: "GETSAVEDCARDS", // GETSAVEDCARDS
    savedCardIDs: opensearch_id_array,
    language_pref: "en",
  };

  // getHitData for the alertCard metadata that lives in opensearch, not PG
  const environment = dev_v_prod();
  const response = await axios.post(environment.getOSData, params);
  const hitArray = response.data.response.hits;

  let putFirst = "";

  // Add in the highlights from PG alert_cards
  for (const card of savedAlertCards) {
    for (const hit of hitArray) {
      if (card.opensearch_id === hit.indexID) {
        // Assign highlights
        hit["highlightArray"] = [];
        for (const highlight of card.highlight_array) {
          hit["highlightArray"].push(JSON.parse(highlight));
        }
        // Assign autoplay, if valid, to correct highlight
        if (card.id === constructIds[0]) {
          hit["highlightAutoplay"] = autoplayIndex;
          putFirst = hit.indexID;
        }
      }
    }
  }

  // Sort so the matched card is on top
  hitArray.sort((a, b) => {
    if (a.indexID === putFirst) {
      return -1;
    } else if (b.indexID === putFirst) {
      return 1;
    } else {
      return 0;
    }
  });

  // Get the associated Postgres data and mix it with hit data
  // const pgData = usePostgresMetadata(hitArray);
  const pgData = await fetchPostgresMetadata(hitArray);

  const pageLoadData = {stations: pgData.response.rows.map((row) => new Station(row))};

  const data = addMetadata({pageLoadData, hits: hitArray});

  return data;
}