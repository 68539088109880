import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { dev_v_prod } from "../Components/APICalls/dev_v_prod";

const getPresignedUrl = async ({ queryKey }: { queryKey: [string, string] }) => {
  const [_, keyPath] = queryKey;
  if (!keyPath) {
    return null;
  }

  const environment = dev_v_prod();

  const data = await axios.get(`${environment.getPresignedUrl}?keyPath=${keyPath}`);
  const presignedUrl: string = data?.data?.presignedUrl || "";
  return presignedUrl;
};

const usePresignedUrl = (keyPath?: string) => {
  // const [presignedCount, setPresignedCount] = useState(0);

  const { data: presignedUrl, error } = useQuery({
    queryKey: ["getPresignedUrl", keyPath || ""],
    queryFn: getPresignedUrl,
    staleTime: Infinity,
    enabled: !!keyPath,
  });

  // console.log("presignedUrl: ", presignedUrl);
  if (error) {
    console.log("error: ", error);
  }

  return { presignedUrl, error };
};

export default usePresignedUrl;
