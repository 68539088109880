import { dev_v_prod } from "../../APICalls/dev_v_prod";

const environment = dev_v_prod();

export default async function handleDeleteSavedCard (id) {
  await (
    await fetch(environment.fqPost, {
      method: "POST",
      body: JSON.stringify({
        handleDeleteSavedCard: true,
        id: id,
      }),
    })
  ).json();
}