

export default function langCodeLookup (langCode) {
let langCodeCleaned = langCode;
// Language code look up
// https://liquidcinemavr.com/support-articles/iso-2-digit-language-codes/
if (langCode.slice(0,2) === "en") langCodeCleaned = "English";
else if (langCode.slice(0,2) === "fr") langCodeCleaned = "French";
else if (langCode.slice(0,2) === "af") langCodeCleaned = "Afrikaans";
else if (langCode.slice(0,2) === "pt") langCodeCleaned = "Portuguese";
else if (langCode.slice(0,2) === "ar") langCodeCleaned = "Arabic";
else if (langCode.slice(0,2) === "fa") langCodeCleaned = "Farsi";
else if (langCode.slice(0,2) === "ru") langCodeCleaned = "Russian";
else if (langCode.slice(0,2) === "id") langCodeCleaned = "Indonesian";
else if (langCode.slice(0,2) === "es") langCodeCleaned = "Spanish";
else if (langCode.slice(0,2) === "uk") langCodeCleaned = "Ukrainian";
return langCodeCleaned;
}

