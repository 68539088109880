import React, { useState, useEffect } from "react";

import "../Styles/index.css";
import "../Styles/gridLayout.css";
import "../Styles/alertCards.css";
import { ThemeProvider } from "@mui/material/styles";
import { fqTheme } from "../Styles/themeCustomization";

import { HitCard } from "../Components/BroadcastCards/HitCard";
import loadAlertCards from "./loadAlertCards";

// Main shareAlertCards function
export default function Alerts() {
  const [hitData, setHitData] = useState([]);
  const [showData, setShowData] = useState(false);

  // Get the data for the cards to display
  useEffect(() => {
    const runLoad = async () => {
      const hitData = await loadAlertCards();
      setHitData(hitData);
      setShowData(true);
    };
    runLoad();
  }, []);

  const alertCardsFooter = (
    <div>
      <div className="">
        <a href="https://frequency.rootwise.co" target="_blank" rel="noreferrer">
          Log in
        </a>{" "}
        to discover additional content.
      </div>
      <div className="mt10">
        For questions or dashboard access,{" "}
        <a href="https://www.rootwise.co/contact/" target="_blank" rel="noreferrer">
          contact RootWise
        </a>
        .
      </div>
    </div>
  );

  if (!showData) {
    return <div className="loader l-normal"></div>;
  }

  return (
    <ThemeProvider theme={fqTheme}>
      <div className="no-scroll">
        <div className="alertCard-container scroll-y">
          <div className="justify-c">
            <img
              src="https://ampmedia.blob.core.windows.net/global-files/RootWise Frequency (transparent).png"
              alt="RootWise Logo"
              className="fq-logo"
            />
          </div>
          <div>
            {hitData.map((currentHit) => (
              <div className="" key={currentHit.indexID}>
                <HitCard
                  key={currentHit.indexID}
                  values={currentHit}
                  shareAlertCard={true}
                  // autoplayIndex={autoplayIndex}
                />{" "}
              </div>
            ))}
          </div>
          <div className="mt20 center">{alertCardsFooter}</div>
        </div>{" "}
      </div>
    </ThemeProvider>
  );
}
