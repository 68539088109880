interface Props {
  text: string;
}

// Extract keyword strings
const getStrongTags = (props: Props): string[] => {
  let keywordMatches: string[] = [];
  const strongTagsRegex = /<strong>(.*?)<\/strong>/g;
  const matches = props.text.match(strongTagsRegex);

  if (matches) {
    keywordMatches = matches.map((match) => match.replace(/<\/?strong>/g, ""));
  }
  return keywordMatches;
};

export default getStrongTags;
