export enum SearchTypes {
  GetData = "getData",
  GetSavedCards = "getSavedCards",
  GETLAST10 = "GETLAST10",
}

// TODO: This should be a class
export enum UploadTypes {
  DrcWhatsApp = "DRC WhatsApp",
}

export enum TranscriptionLanguages {
  Detect = "auto",
  Arabic = "ar-SA",
  Chinese = "zh-CN",
  English = "en-US",
  French = "fr-FR",
  Swahili_Standard = "sw-TZ",
  Swahili_Rwandan = "sw-RW",
  Swahili_Kenyan = "sw-KE",
  Ukrainian = "uk-UA",
  Russian = "ru-RU",
}
