import { useContext } from "react";

import { CurrentHighlight } from "./CurrentHighlight";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "./CardHeader";

import { SearchTermContext } from "../DataManagement/InitContexts";

//////////////
// Build a Card of matching Hits to user search query
//////////////

export const HitCard = (props) => {
  // Build ClipCard Header
  const searchTerm = useContext(SearchTermContext);
  const clipCardHeader = (
    <CardHeader
      values={props.values}
      showStar={searchTerm !== "GETLAST10" && props.shareAlertCard !== true}
    />
  );

  // Build the highlightArray
  let highlightsArray = [];

  if (searchTerm !== "GETLAST10") {
    // Shut down Cards / Highlights while system is reloading
    if (props.values.highlightArray === undefined) return;

    highlightsArray = props.values.highlightArray;
  } else {
    // Block for GETLAST10: Just use the first clip as a "highlight"
    highlightsArray.push({
      text: props.values._source?.clips[0] ? props.values._source.clips[0].clip.original : "",
      language: "en",
      clipStartTime: props.values._source?.clips[0] ? props.values._source.clips[0].startTime : "",
      clipEndTime: props.values._source?.clips[0] ? props.values._source.clips[0].endTime : "",
      clipOffset: 0,
      translated: false,
    });
  }

  return (
    <div className="card inline-div">
      <Card sx={{ backgroundColor: "", display: "grid", minWidth: 275, maxWidth: 600 }}>
        <CardContent>
          {clipCardHeader}

          {highlightsArray.map((highlight, index) => (
            <CurrentHighlight
              key={index}
              values={props.values}
              highlight={highlight}
              showShareButton={searchTerm !== "GETLAST10" && props.shareAlertCard !== true}
            />
          ))}
        </CardContent>
      </Card>
    </div>
  );
};
