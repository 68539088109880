import { useState, useEffect } from "react";


interface Props {
  setFilters: (values: undefined) => void;
  setNewFilterOptions: (values: undefined) => void;
}

// Manage date range for the app
const useDateRange = ({setFilters, setNewFilterOptions}: Props) => {
  const [startDateRange, setStartDateRange] = useState<number>(0);
  const [endDateRange, setEndDateRange] = useState<number>(0);

  // Initial set to last 30 days
  useEffect(() => {
    let lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 29);
    lastWeek.setHours(0, 0, 0, 0);
    const startD = lastWeek.getTime() / 1000;
    const endD = new Date().getTime() / 1000;

    setStartDateRange(startD);
    setEndDateRange(endD);
  }, []);

  // Handle user date range adjustments
  const handleDateRange = async (event: any) => {
    const start = event.selection.startDate.getTime() / 1000;
    const end = event.selection.endDate.getTime() / 1000;
    setStartDateRange(start);
    setEndDateRange(end);
    setFilters(undefined);
    setNewFilterOptions(undefined);
  };

  return { startDateRange, endDateRange, handleDateRange };
};

export default useDateRange;
