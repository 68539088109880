import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DialogBox(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = async (proceed) => {
    setOpen(false);
    props.setParentOpen(false);

    if (proceed && props.handleProceed !== undefined) {
      props.handleProceed(true);
    }
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.params.title}</DialogTitle>
        <DialogContent>
          <DialogContentText component={"div"} id="alert-dialog-description">
            {props.params.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.params.buttons.map((bParams, index) => (
            <Button
              variant={bParams.variant}
              key={index}
              onClick={() => handleClose(bParams.proceed)}
              disabled={bParams.disabled}
            >
              {bParams.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );
}
