import { createTheme } from '@mui/material/styles';

export const fqTheme = createTheme({
  palette: {
    primary: {
      main: '#155436',
    },
  
  },

  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardSuccess': {
            color: '#155436',
            backgroundColor: 'rgba(21, 84, 54, 0.1)',
          },
          '&.MuiAlert-standardError': {
            color: '#155436',
            backgroundColor: 'rgba(21, 84, 54, 0.1)',
          },
          '&.MuiAlert-standardWarning': {
            color: '#155436',
            backgroundColor: 'rgba(21, 84, 54, 0.1)',
          },
          '&.MuiAlert-standardInfo': {
            color: '#155436',
            backgroundColor: 'rgba(21, 84, 54, 0.1)',
          },
        },
      },
    },
  },

  typography: {
    h4: {
      fontSize: 18
      // fontWeight: 600,
    },
  },
});

