import DialogBox from "../Navigation/DialogBox";
// import { Typography } from "@mui/material/styles/createTypography";
import { Typography } from "@mui/material";

export const SearchHelpGuide = (props) => {
  const params = {
    title: "Quick Help for Search",
    buttons: [],
  };

  params.description = (
    <div>
      <Typography color="text.primary">
        Search is not case sensitive. <br />
        Use AND, OR, AND NOT to join words or phrases (must be capitalized). <br />
        * is a wildcard. <br />
        "" wraps several terms into a phrase. <br />
        () wraps a clause for precedence. <br />
        ~ When used after a term, sets allowable fuzziness (e.g. foobtall~2 matches "football"). When used after a phrase, sets allowable distance between words (e.g., "player match"~5 matches "the player had a good match"). <br />
        OR is the default. Words and phrases separated by a space use OR logic. 
      </Typography>
      <Typography variant="h6">Examples:</Typography>
      <Typography color="text.primary">
        pizza OR pasta
        <br />
        pizza AND sauce
        <br />
        pizza AND slic*
        <br />
        (pizza AND sauce) AND NOT pasta
        <br />
        (pizza OR pasta) AND sauce
        <br />
        (pizza OR pasta) AND (sauce OR slice)
        <br />
        "New York" AND pizza
        <br />
        "New York pizza"~5
        <br />
        pizaz~1
        <br />
        ((pizaz~1 AND "New York") AND (style crust*)) AND NOT "Chicago style"~3
      </Typography>
    </div>
  );

  params.buttons.push({
    variant: "contained",
    proceed: false,
    text: "Close",
  });

  const setParentOpen = () => {
    props.setShowSearchHelp(false);
  }

  return (
    <div>
      <DialogBox params={params} setParentOpen={setParentOpen} />
    </div>
  );
};
