// Global
import React, { useState } from "react";

// MUI & Formik
import {
  Box,
  TextField,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Dialog,
  Stack,
  Select,
} from "@mui/material";
import { Formik, Form, Field } from "formik";

// Local Imports
import { PageLoadData } from "../../Configs/interfaces";

interface SaveSearchDialogProps {
  pageLoadData: PageLoadData;
  open: boolean;
  customSearchTerm: string;
  onClose: () => void;
  mutation: any;
  //   onSave: (data: SaveData) => void;
}

const SaveSearchDialog: React.FC<SaveSearchDialogProps> = ({
  pageLoadData,
  open,
  customSearchTerm,
  onClose,
  mutation,
}) => {
  // Deconstruct ridiculous format of projects (two arrays of ids and names)
  // Build a sensible projects[] object
  const [projects, setProjects] = useState<{ id: number; name: string }[]>(
    pageLoadData.projects.ids.map((_, i) => ({
      id: pageLoadData.projects.ids[i],
      name: pageLoadData.projects.names[i],
    }))
  );

  const handleSave = (values: Record<string, any>) => {
    // onSave({ searchTerm: customSearchTerm, project, visibility });

    console.log("values", values.searchName);

    // addQuery for team (project-level)
    if (values.visibility === "private") {
      mutation.mutate({
        action: "addQueryForUser",
        userId: pageLoadData.user_id,
        queryData: {
          queryName: values.searchName,
          queryString: customSearchTerm,
          displayOrder: 5
        },
      });
    }

    // addQuery for individual (whitelist)
    else {
      mutation.mutate({
        action: "addQueryForTeam",
        projectId: values.projectId,
        queryData: {
          queryName: values.searchName,
          queryString: customSearchTerm,
          displayOrder: 4
        },
      });
    }

    onClose(); // Close the dialog after saving
  };

  // Initial values for form
  const initialValues = {
    searchQuery: customSearchTerm,
    searchName: "",
    projectId: projects?.[0]?.id,
    visibility: "private",
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik initialValues={initialValues} onSubmit={handleSave}>
        {({ setFieldValue, values }) => (
          <Form>
            <Box width={500} m={4}>
              {/* Search Query (disabled) */}
              <Field
                as={TextField}
                name="searchQuery"
                fullWidth
                label="Search Query"
                variant="outlined"
                multiline
                maxRows={3}
                // value={customSearchTerm}
                disabled
                margin="dense"
              />

              {/* Project selection */}
            {projects.length > 1 && <Field
                as={Select}
                name="projectId"
                label="Project"
                fullWidth
                disabled={projects.length === 1}
              >
                {projects &&
                  projects.map((project) => (
                    <MenuItem value={project.id} key={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
              </Field>}

              {/* Search name */}
              <Field
                as={TextField}
                name="searchName"
                label="Search Name"
                variant="outlined"
                fullWidth
                margin="dense"
                required
                placeholder="Give this search a name"
              />

              {/* Visibility: team or just user */}
              <Field as={RadioGroup} name="visibility" row>
                <FormControlLabel value="private" control={<Radio />} label="Just for Me" />
                <FormControlLabel value="team" control={<Radio />} label="Share with Team" />
              </Field>
              <Stack direction="row" justifyContent="end" spacing={2}>
                <Button variant="text" onClick={onClose}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" disabled={values.searchName.length < 1}>
                  Save
                </Button>
              </Stack>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default SaveSearchDialog;
