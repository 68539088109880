import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";

import { useAuth0 } from "@auth0/auth0-react";
import AlertDialog from "./AlertDialogue";
import { Button } from "@mui/material";
import { dev_v_prod } from "../APICalls/dev_v_prod";
import axios from "axios"

export default function ShowExistingAlerts() {
  const [alertValues, setAlertValues] = useState([]);
  const [alertParams, setAlertParams] = useState({});
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [tableRefresh, setTableRefresh] = useState(0);
  const { user } = useAuth0();

  //
  // console.log("In ShowExistingAlerts index.js. response:")
  // console.dir(response);

  useEffect(() => {
    const getTableData = async () => {
      const params = {
        alerts: "getAlerts",
        username: user.name,
      };

      const environment = dev_v_prod();
      const res = await axios.get(environment.fqGet, { params: params });

      // console.log(res);
      
      // Get data from postgres alerts table as res.data.response
      // Then loop through alerts and create "currentAlert" object
      for (const alert of res.data.response) {
        const currentAlert = {
          id: alert.id,
          // project: alert._source.project,
          alertName: alert.name,
          alertQuery: alert.query,
          alertProject: alert.project_name,
          alertFrequency: alert.frequency,
          // recipients: alert?.other_emails !== null ? ,
          recipients: alert?.other_emails !== null ? alert.user_email + ", " + alert.other_emails.join(", ") : alert.user_email,
          languagePref: alert.language_pref,
          lastRun: alert?.prior_check_time !== null ? new Date(alert.prior_check_time).toLocaleString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "2-digit",
            timeZoneName: "short"
          }) : null,
        };

        // console.log(currentAlert)

        setAlertValues((current) => [...current, currentAlert]);
      }
    };

    setAlertValues([]);
    getTableData();
  }, [user.name, tableRefresh]);

  const handleHideDeleteAlert = () => {
    setShowDeleteAlert(false);
  };

  const handleDeleteAlert = (alertID, alertName) => {
    setAlertParams({
      title: `Delete this alert?`,
      desc: alertName,
      noButton: "Nevermind",
      yesButton: "OK",
      alertID: alertID,
    });
    setShowDeleteAlert(true);
  };

  const deleteAlert = async (id) => {
    // let response = await fetch(`api/DeleteAlert?id=` + id);

    const environment = dev_v_prod();
    const response = await fetch(environment.fqPost, {
      method: "POST",
      body: JSON.stringify({
        alerts: "deleteAlert",
        alertID: id,
      }),
    });
  
    await response.text();
  
    const newAlertList = alertValues.filter(alert => alert.id !== id);
    setAlertValues(newAlertList);
  }

  const refreshTable = () => {
    setTableRefresh(tableRefresh + 1);
  };



  return (
    <div className="alert-modal">
      <div className="float-r">
        <Button onClick={refreshTable}>Refresh</Button>
      </div>

      <TableContainer
        component={Paper}
        sx={{ height: 1 }}
        className="mt10"
      >
        <Table sx={{ width: 650 }} aria-label="Existing Alerts">
          <TableHead >
            <TableRow>
              <TableCell>Alert Name</TableCell>
              <TableCell align="left">Alert Query</TableCell>
              <TableCell align="left">Alert Frequency</TableCell>
              <TableCell align="left">Project</TableCell>
              <TableCell align="left">Recipients</TableCell>
              <TableCell align="left">Language Preference</TableCell>
              <TableCell align="left">Last Run</TableCell>
              <TableCell align="left">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alertValues.map((alert) => (
              <TableRow
                key={alert.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {alert.alertName}
                </TableCell>
                <TableCell align="left">{alert.alertQuery}</TableCell>
                <TableCell align="left">{stringifyFrequency(alert.alertFrequency)}</TableCell>
                <TableCell align="left">{alert.alertProject}</TableCell>
                <TableCell align="left">{alert.recipients}</TableCell>
                <TableCell align="left">{alert.languagePref}</TableCell>
                <TableCell align="left">{alert.lastRun}</TableCell>
                <TableCell align="center">
                  <DeleteIcon
                    className="text link"
                    onClick={() => handleDeleteAlert(alert.id, alert.alertName)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {showDeleteAlert && (
        <AlertDialog
          params={alertParams}
          deleteAlert={deleteAlert}
          hideDeleteAlert={handleHideDeleteAlert}
          // refreshTable={refreshTable}
        />
      )}
    </div>
  );
}



function stringifyFrequency(frequency) {
  // convert alertFrequency to plain language
  let alertFrequency;
  switch (parseInt(frequency)) {
    case 60:
      alertFrequency = "hourly";
      break;
    case 1440:
      alertFrequency = "daily";
      break;
    case 10080:
      alertFrequency = "weekly";
      break;
    default:
      alertFrequency = "15 minutes";
  }

  return alertFrequency;
}