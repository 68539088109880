import React from "react";
import Typography from "@mui/material/Typography";

///////////
// Clip Details
///////////
export const CardDetails = (props) => {
  let { device, clipLength, tags } = "";

  if (props.values._source.sourceMetadata !== undefined) {
    device = (
      <div className="mt3">
        <div className="inline-div text-secondary">Recording Device:</div>{" "}
        {props.values._source.sourceMetadata.deviceName} (
        {props.values._source.sourceMetadata.deviceId})
      </div>
    );

    if (props.values._source.sourceMetadata.duration) {
      let minutes = "< 1 minute";
      if (props.values._source.sourceMetadata.duration > 60) {
        minutes = Math.floor(props.values._source.sourceMetadata.duration / 60) + " minutes";
      }

      clipLength = (
        <div className="mt3">
          <div className="inline-div text-secondary">Broadcast Length:</div> {minutes}
        </div>
      );
    }

    // Attributes to put in links
    const URLArray = [
      "url",
      "transmitter_map",
      "stationWebsite",
      "stationFacebook",
      "stationTwitter",
      "programWebsite",
      "programFacebook",
      "programTwitter",
    ];
    // Attributes to skip
    // Many of these attributes are displayed on the card, just not via this widget
    const skipArray = [
      "name",
      "countryLat",
      "countryLng",
      "preciseLat",
      "preciseLng",
      "languagePref",
      "location",
      "duration",
      "start",
      "end",
      "epoch",
      "deviceId",
      "deviceName",
      "program_id",
    ];

    // Display all other tags, respecting URLArray styling and skipArray filters
    tags = (
      <div>
        {Object.keys(props.values._source.sourceMetadata)
          .filter(
            (filterKey) =>
              !skipArray.includes(filterKey) &&
              props.values._source.sourceMetadata[filterKey] !== null &&
              props.values._source.sourceMetadata[filterKey] !== undefined
          )
          .map((key, index) => {
            if (URLArray.some((term) => term === key.toLowerCase())) {
              return (
                <div className="mt3" key={index}>
                  <div className="inline-div text-secondary">{key}:</div>{" "}
                  <a
                    href={props.values._source.sourceMetadata[key].toString()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open in new window
                  </a>
                </div>
              );
            } else {
              return (
                <div className="mt3">
                  <div className="inline-div text-secondary">{key}:</div>{" "}
                  {props.values._source.sourceMetadata[key].toString()}
                </div>
              );
            }
          })}
      </div>
    );

    return (
      <div>
        <div>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Broadcast Details
          </Typography>
        </div>
        {tags}
        {clipLength}
        {device}
        <div className="mt3">
          <div className="inline-div text-secondary">Station Code:</div>{" "}
          {props.values._source.sourceMetadata.stationCode}{" "}
        </div>
        <div className="mt3">
          <div className="inline-div text-secondary">ID:</div> {props.values.indexID}{" "}
        </div>
      </div>
    );
  }
};
