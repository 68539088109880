export class Station {
  country: string;
  countryLat: number;
  countryLng: number;
  frequency: string | undefined;
  languagePref: string;
  location: string | undefined;
  preciseLat: number | undefined;
  preciseLng: number | undefined;
  stationCode: string;
  stationDescription: string;
  stationFacebook: string | undefined;
  stationName: string;
  stationTwitter: string | undefined;
  stationWebsite: string | undefined;
  transmitterMap: string | undefined;

  constructor(data: any) {
    this.country = data.country;
    this.countryLat = data.country_lat;
    this.countryLng = data.country_lng;
    this.frequency = data.frequency ?? undefined;
    this.languagePref = data.language_pref;
    this.location = data.location ?? undefined;
    this.preciseLat = data.precise_lat ?? undefined;
    this.preciseLng = data.precise_lng ?? undefined;
    this.stationCode = data.station_code;
    this.stationDescription = data.station_description;
    this.stationFacebook = data.station_facebook ?? undefined;
    this.stationName = data.station_name;
    this.stationTwitter = data.station_twitter ?? undefined;
    this.stationWebsite = data.station_website ?? undefined;
    this.transmitterMap = data.transmitter_map ?? undefined;
  }
}
