import { HttpRequest } from "@aws-sdk/protocol-http";
import { S3RequestPresigner } from "@aws-sdk/s3-request-presigner";
import { parseUrl } from "@aws-sdk/url-parser";
// import { Hash } from "@aws-sdk/hash-node";
import { formatUrl } from "@aws-sdk/util-format-url";
import { Sha256 } from "@aws-crypto/sha256-browser";
// S3 Presigned link expiration in seconds
const expires = 10800;

export default async function getPlaybackLink(deviceId, jobName, keyDatePath, playbackTimestamps, audioType) {
  // Get the audio highlight URL from S3
  // This is what you need: Spice FM.08-15-2022_00-00.mp3
  const datePath = keyDatePath ? keyDatePath : "";

  // Full path of S3 recording in bucket
  const audioExt = audioType ? audioType : 'mp3';
  const key = `recordings/${deviceId}/${datePath}${jobName}.${audioExt}`;
  const bucket = import.meta.env.VITE_AWS_S3_BUCKET;
  const region = import.meta.env.VITE_AWS_REGION;
  const credentials = {
    accessKeyId: import.meta.env.VITE_AWS_S3_ACCESS_KEY_ID,
    secretAccessKey: import.meta.env.VITE_AWS_S3_SECRET_ACCESS_KEY,
  };

  const s3ObjectUrl = parseUrl(`https://${bucket}.s3.amazonaws.com/${key}`);

  const presigner = new S3RequestPresigner({
    credentials,
    region,
    // sha256: Hash.bind(null, "sha256"), // In Node.js
    sha256: Sha256 // In browsers
  });

  const url = await presigner.presign(new HttpRequest(s3ObjectUrl), { expiresIn: expires });
  const fullAudioURL = formatUrl(url);

  // const fullAudioURL = s3.getSignedUrl("getObject", params);

  // Combine URL with start/stop time, return bundled string
  // Syntax example: fullAudioURL + "#t=10,15"
  const audioURLTimeStamped = `${fullAudioURL}#t=${playbackTimestamps[0]},${playbackTimestamps[1]}`;
  return audioURLTimeStamped;
}
