import React, { useState, useEffect, useContext } from "react";
import ReactHtmlParser from "html-react-parser";

import Typography from "@mui/material/Typography";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ShareIcon from "@mui/icons-material/Share";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import getPlaybackLink from "./getPlaybackLink";
import HighlightDrilldown from "./HighlightDrilldown";
import getHighlightTimestamp from "./getHighlightTimestamp";
import langCodeLookup from "../DataManagement/DataCleaning/langCodeLookup";
import createShareAlertCard from "../DataManagement/createShareAlertCard";

import { PageLoadContext } from "../DataManagement/InitContexts";

export const CurrentHighlight = (props) => {
  const pageLoadData = useContext(PageLoadContext);
  const [audioURL, setAudioURL] = useState("");
  const [showPlayer, setShowPlayer] = useState(false);
  const [togglePlayer, setTogglePlayer] = useState(false);
  const [playButtonStyle, setPlayButtonStyle] = useState(
    "inline-div link m5 text"
  );
  const [showHighlightDrilldown, setShowHighlightDrilldown] = useState(false);

  const [highlightText, setHighlightText] = useState(props.highlight.text);
  const [highlightMetadata, setHighlightMetadata] = useState(
    getHighlightMetadata(props.highlight, props.values._source)
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);

   // Monitor for changes to highlightext
   useEffect(() => {
    setHighlightText(props.highlight.text);
  }, [props.highlight.text]);

  /////
  // This function returns the PlayCircleIcon for listening to clips
  // And it returns the HighlightDrilldown function for viewing the highlight in context
  /////
  const highlightActions = (
    <div>
      <div className={playButtonStyle} onClick={() => handlePlayHighlight()}>
        <Tooltip title="Play clip" placement="top" arrow>
          <PlayCircleOutlineIcon />
        </Tooltip>
      </div>
      <div>
        <Tooltip title="Expand clip" placement="left" arrow>
          <ManageSearchIcon
            className="inline-div link m5 text"
            onClick={() => setShowHighlightDrilldown(!showHighlightDrilldown)}
          />
        </Tooltip>
      </div>
      {props.showShareButton && (
        <div>
          <Tooltip title="Share clip" placement="bottom" arrow>
            <ShareIcon
              className="inline-div link m5 text"
              onClick={() => shareAlertCard()}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );

  /////
  // Audio player controls and settings
  /////

  // Loads the audio player and gets the audioURL
  useEffect(() => {
    const getPlayer = async () => {
      if (!audioURL) {
        let fullAudioURL = "";

        if (!highlightMetadata.translated) {
          // Get precision playback audio
          fullAudioURL = await getPlaybackLink(
            props.values._source.sourceMetadata.deviceId,
            props.values._source.jobName,
            props.values._source.sourceMetadata.keyDatePath,
            highlightMetadata.playbackTimestamps,
            props.values._source.audioType
          );
        } else {
          // Estimate playback audio within the clip
          // const estPlaybackStart = estimatePlayBackTimeCodes(props.values, props.highlight);
          const estPlayback = props.highlight.playbackTimestamps;
          fullAudioURL = await getPlaybackLink(
            props.values._source.sourceMetadata.deviceId,
            props.values._source.jobName,
            props.values._source.sourceMetadata.keyDatePath,
            estPlayback,
            props.values._source.audioType
          );
        }
        setAudioURL(fullAudioURL);
      }
      setShowPlayer(true);
    };
    if (togglePlayer) getPlayer();
    else setShowPlayer(false);
  }, [props, togglePlayer, highlightMetadata, audioURL]);

  // Toggles whether to display the Player
  const handlePlayHighlight = async () => {
    // Style button
    if (playButtonStyle === "inline-div link m5 text") {
      setPlayButtonStyle("inline-div link m5 contrast-text");
    } else {
      setPlayButtonStyle("inline-div link m5 text");
    }
    if (!showPlayer) {
      setTogglePlayer(true);
    } else {
      // If player is already visible, hide it
      setTogglePlayer(false);
    }
  };

  // Play the auto automatically if conditions match
  // Condition is if this is the primary shareAlertCard
  useEffect(() => {
    if (
      props.highlight?.highlightIndex === props.values?.highlightAutoplay &&
      props.highlight?.highlightIndex !== undefined
    ) {
      setPlayButtonStyle("inline-div link m5 contrast-text");
      setTogglePlayer(true);
    }
  }, [props]);

  /////
  // Other functions
  /////

  // Handle user wanting more information about a highlight
  const handleHighlightDrilldown = () => {
    setShowHighlightDrilldown(!showHighlightDrilldown);
  };

  // Handle user creating an alertCard
  // Call PG to create the entry in alert_cards
  // Create a URL and copy to user's clipboard
  const shareAlertCard = async () => {
    const shareURL = await createShareAlertCard(
      props.values.indexID,
      pageLoadData.username,
      props.values.highlightArray,
      props.highlight.highlightIndex
    );

    // Copy URL to clipboard and notify user
    navigator.clipboard.writeText(shareURL).then(() => {
      setSnackbarOpen(true);
    });
  };

  // Handle show duplicate versions
  const handleSwapDuplicates = (duplicate) => {
    const resultHighlightMetadata = getHighlightMetadata(
      duplicate,
      props.values._source
    );

    setHighlightMetadata(resultHighlightMetadata);
    setHighlightText(duplicate.text);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  /////
  // Main return block
  /////

  return (
    <div className="highlight-div">
      <div>{highlightActions}</div>

      <div>
        <div>
          {showPlayer && (
            <audio controls autoPlay src={audioURL} controlsList="nodownload" />
          )}
        </div>

        <div className="text-secondary">
          {highlightMetadata.highlightHeader}{" "}
          <div className="inline-div float-r">
            {" "}
            <ShowDuplicatesUI
              highlight={props.highlight}
              handleSwapDuplicates={handleSwapDuplicates}
              currentHighlightText={highlightText}
            />
          </div>
        </div>
        <div className="mt5">
          <Typography variant="body2">
            {ReactHtmlParser(highlightText)}
          </Typography>
        </div>
      </div>

      {showHighlightDrilldown && (
        <HighlightDrilldown
          values={props.values}
          highlight={props.highlight}
          showHighlightDrilldown={showHighlightDrilldown}
          handleHighlightDrilldown={handleHighlightDrilldown}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Shareable URL copied to clipboard!
        </Alert>
      </Snackbar>
    </div>
  );
};

/////
// Sub Functions
/////

// Set the highlight header and playback timestamps
function getHighlightMetadata(highlight, _source) {
  // Get the start and end times of the highlight
  // Return as an array playbackTimestamps: position 0 = start, position 1 = end
  let playbackTimestamps = [highlight.clipStartTime, highlight.clipEndTime];
  if (!highlight.translated && highlight.playbackTimestamps) {
    // playbackTimestamps = getPlayBackTimeCodes(_source.skinnyItems, highlight);
    playbackTimestamps = highlight.playbackTimestamps;
  }

  // Build the Timestamp visual above each highlight
  let highlightHeader = getHighlightTimestamp(
    playbackTimestamps[0],
    playbackTimestamps[1]
  );
  if (highlightHeader === "0:00 - 0:01") highlightHeader = "";

  // This block adds the text translation notification, if applicable.
  if (highlight.translated) {
    const langCodeCleaned = langCodeLookup(
      _source.sourceMetadata.language_code
    );

    highlightHeader =
      highlightHeader + ". Translated from " + langCodeCleaned + ".";
  }

  return {
    highlightHeader: highlightHeader,
    playbackTimestamps: playbackTimestamps,
    translated: highlight.translated,
  };
}

/////
// Handling duplicate/alternate highlights
////

const ShowDuplicatesUI = (props) => {
  let duplicatesTextBuilder = [];
  let i = 0;
  // See if any duplicates exist
  if (props.highlight.duplicates !== undefined) {
    for (const duplicate of props.highlight.duplicates) {
      // If yes, notify user for each duplicate
      if (duplicate.text !== props.currentHighlightText) {
        // Convert langCode to plain english
        const cleanedLangCode = langCodeLookup(duplicate.language);

        // Interactive notice to user
        let textForUser = (
          <span>Show a similar result in {cleanedLangCode}</span>
        );
        if (duplicatesTextBuilder.length > 0)
          textForUser = <span>. Show another.</span>;
        duplicatesTextBuilder.push(
          <div
            key={i}
            className="inline-div link"
            onClick={() => props.handleSwapDuplicates(duplicate)}
          >
            {textForUser}
          </div>
        );
        i++;
      }
    }
    // Display the "show original"
    if (props.highlight.text !== props.currentHighlightText) {
      let textForUser = <span>Show original result</span>;
      if (duplicatesTextBuilder.length > 0)
        textForUser = <span>. Show original result.</span>;

      duplicatesTextBuilder.push(
        <div
          key={i}
          className="inline-div link"
          onClick={() => props.handleSwapDuplicates(props.highlight)}
        >
          {textForUser}
        </div>
      );
    }
  }
  return <div> {duplicatesTextBuilder}</div>;
};
