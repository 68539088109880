// Global
import { useState, useContext } from "react";

// MUI
import { Box, Typography, Stack, FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import DownloadButton from "../Buttons/DownloadButton";
import InfoIcon from "@mui/icons-material/Info";

// Local
import { DateObjContext, SearchTermContext } from "../DataManagement/InitContexts";
import MetadataFilters from "./MetadataFilters";
import { FilterControls } from "../../Hooks/useFilters";

interface Props {
  clipData: any;
  isOldestFirst: boolean;
  handleIsOldestFirst: (value: boolean) => void;
  filterControls: FilterControls;
}

const Subheader = (props: Props) => {
  // Load context
  const dateObj = useContext(DateObjContext);
  const searchTerm = useContext(SearchTermContext);

  // Convert dates to human-readable
  const startDate = new Date(dateObj.startDateRange * 1000).toString();
  const endDate = new Date(dateObj.endDateRange * 1000).toString();
  const [showDetails, setShowDetails] = useState(false);

  /* UNFOLD section: Show Search Details */
  // Shows date range and search term to user, upon request
  const searchDetails = (
    <div className="text-black mt10">
      <div>
        <Typography>Search Details</Typography>
      </div>
      <div>Start Date: {startDate} </div>
      <div>End Date: {endDate} </div>
      <div>
        Query:
        <div className="inline-div m5 fixed-width">{searchTerm}</div>
        {/* <div className='inline-div link text-secondary m10' onClick={props.handleCustomize}>Customize this query</div> */}
      </div>
    </div>
  );

  return (
    <Box>
      <Stack
        direction="row"
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        my={1.5}
      >
        {/* Clip Count and Sort By */}
        <Stack direction="row" display="flex" spacing={1} alignItems={"center"}>
          <Typography variant={"h4"}>Clips: {props.clipData.totalHits}</Typography>
          <FormControl margin="dense" size="small">
            <Select
              value={props.isOldestFirst}
              onChange={() => props.handleIsOldestFirst(!props.isOldestFirst)}
              labelId="sortBy-select-label"
              id="sortBy-select"
            >
              <MenuItem value={"false"}>Newest First</MenuItem>
              <MenuItem value={"true"}>Oldest First</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        {/* Right-justified subheader */}
        <Stack
          direction="row"
          display="flex"
          justifyContent={"end"}
          spacing={1}
          alignItems={"center"}
        >
          {/* Country and station filters */}
          <MetadataFilters filterControls={props.filterControls} />

          {!showDetails && (
            <Tooltip title="Show search details" placement="bottom" arrow>
              <InfoIcon
                fontSize="large"
                color="primary"
                onClick={() => setShowDetails(!showDetails)}
              />
            </Tooltip>
          )}
          {showDetails && (
            <Tooltip title="Hide search details" placement="bottom" arrow>
              <InfoIcon
                fontSize="large"
                color="primary"
                onClick={() => setShowDetails(!showDetails)}
              />
            </Tooltip>
          )}

          <DownloadButton searchTerm={searchTerm} hits={props.clipData.hits} />
        </Stack>
      </Stack>
      <Box> {showDetails && searchDetails}</Box>
    </Box>
  );
};

export default Subheader;
