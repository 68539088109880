// Global
import moment from "moment";
import pLimit from "p-limit";

// Local
import { Hit } from "../../Models/hit";
import postAlertCardForExport from "../APICalls/postAlertCardForExport";
import getStrongTags from "./getStrongTags";
import { flattenAndDedupeArrays } from "./formatters";
import removeHTMLTags from "./DataCleaning/removeHTMLTags";

interface Props {
  hitData: Hit[];
  user_email: string;
}

// Limit the number of concurrent requests to 10
const limit = pLimit(10);

const exportClipToCSV = async ({ hitData, user_email }: Props) => {
  // Convert hitData to clipData
  let clipDataList: Record<string, any>[] = [];
  hitData.map((hit) => {
    // Clips for specific hit
    let clipData: Record<string, any>[] = [];
    hit.highlightArray.map((highlight) => {
      // Build a clipData from a highlight
      const language = highlight.translated ? highlight.language : "original";
      const highlightRow = {
        text: removeHTMLTags(highlight.text),
        clipText: hit._source.clips[highlight.clipOffset].clip[language],
        translated: highlight.translated,
        language: highlight.language,
        clipOffset: highlight.clipOffset,
        clipStartTime: highlight.clipStartTime,
        clipEndTime: highlight.clipEndTime,
        playbackTimestamps: highlight.playbackTimestamps,
        // Get time from hit epoch
        broadcastTime: moment
          .unix(hit._source.sourceMetadata.epoch)
          .utc()
          .format("YYYY-MM-DD hh:mm:ss"),
        stationName: hit._source.sourceMetadata.stationName,
        indexID: hit.indexID,
        keywordMatches: getStrongTags({ text: highlight.text }),
        highlightIndex: highlight.highlightIndex,

        nlp: {
          locations: highlight.nlp?.LOC ? [...new Set(highlight.nlp?.LOC)] : [],
          otherEntities: flattenAndDedupeArrays(
            highlight.nlp?.MISC,
            highlight.nlp?.ORG,
            highlight.nlp?.PER
          ),
        },
      };
      clipData = [...clipData, highlightRow];
    });
    clipDataList = [...clipDataList, ...clipData];
  });

  return await Promise.all(
    clipDataList.map((clipData) => {
      return limit(async () => {
        const clipHit = {
          indexID: clipData.indexID,
          highlightArray: clipData,
        };

        const shareCardId = await postAlertCardForExport(user_email, clipHit);
        const baseShareCardURL = "https://frequency.rootwise.co/alerts?id=" + shareCardId;

        return {
          broadcastTime: clipData.broadcastTime,
          stationName: clipData.stationName,
          highlight: clipData.text,
          fullClip: clipData.clipText,
          highlightLink: baseShareCardURL + "&highlight=" + clipData.highlightIndex,
          keywordMatches: clipData.keywordMatches.join(", "),
          locations: clipData.nlp?.locations.join(", "),
          otherEntities: clipData.nlp?.otherEntities.join(", "),
        };
      });
    })
  );
};

export default exportClipToCSV;

// Column headers for CSV
export const ClipCSVHeaders = [
  { label: "Broadcast Time", key: "broadcastTime" },
  { label: "Station Name", key: "stationName" },
  { label: "Highlight", key: "highlight" },
  { label: "Audio Clip", key: "highlightLink" },
  { label: "Full Clip", key: "fullClip" },
  { label: "Keywords", key: "keywordMatches" },
  { label: "Locations", key: "locations" },
  { label: "Other Entities", key: "otherEntities" },
];
