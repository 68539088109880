import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { Button } from "@mui/material";

export const CalendarUI = (props) => {
  // Set 14-day default search
  // const lastTwoWeeks = new Date ();
  // lastTwoWeeks.setDate(lastTwoWeeks.getDate()-13);
  const [startRange, setStartRange] = useState(
    new Date(props.startDateRange * 1000)
  );
  const [endRange, setEndRange] = useState(new Date(props.endDateRange * 1000));

  const handleRangeSelect = (ranges) => {
    setStartRange(ranges.selection.startDate);
    setEndRange(ranges.selection.endDate);

    // Don't finish function until user selects both start and end date
    if (ranges.selection.startDate === ranges.selection.endDate)
      return;

    if (ranges.selection.endDate.getSeconds() === 0) {
      ranges.selection.endDate.setDate(ranges.selection.endDate.getDate() + 1);
      ranges.selection.endDate.setSeconds(
        ranges.selection.endDate.getSeconds() - 1
      );
    }

    // Set for rendering, and push up to parent
    // setStartRange(ranges.selection.startDate);
    // setEndRange(ranges.selection.endDate);
    props.dateRange(ranges);
  };

  const selectionRange = {
    startDate: startRange,
    endDate: endRange,
    key: "selection",
  };

  return (
    <div>
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleRangeSelect}
        months={2}
        direction="horizontal"
        colors="#155436"
        calendarFocus="backwards"
        preventSnapRefocus={true} />

      <div className="centered-buttons">
        <Button
          className=""
          variant="contained"
          onClick={() => props.handleShowCalendar(true)}
        >
          Set Date
        </Button>
      </div>
    </div>
  );
};
