// Global
// import { useState } from "react";

// Local
import { dev_v_prod } from "../Components/APICalls/dev_v_prod";
import { useSuspenseQuery } from "@tanstack/react-query";
import { savedCardsQuery } from "../Configs/keyConstants";
import { PageLoadData } from "../Configs/interfaces";

const environment = dev_v_prod();

interface QueryProps {
  queryKey: [string, { project_IDs?: number[], searchTerm: string}];
}

// Business logic for fetching saved card data from Postgres
const getSavedCards = async ({ queryKey }: QueryProps) => {
  const [_key, { project_IDs, searchTerm }] = queryKey;
  // This function gets saved cards from Postgres

  if (!searchTerm || searchTerm !== "GETSAVEDCARDS") {
    return [];
  }

  const resJSON = await (
    await fetch(environment.fqPost, {
      method: "POST",
      body: JSON.stringify({
        getSavedCards: true,
        project_IDs: project_IDs,
      }),
    })
  ).json();

  return resJSON.response.rows;
};

interface Props {
  pageLoadData: PageLoadData;
  searchTerm: string;
}

// State management and fetching for saved cards
const useSavedCards = (props: Props) => {
  //   const [savedCardArray, setSavedCardArray] = useState({});

  const { data } = useSuspenseQuery({
    queryKey: [
      savedCardsQuery,
      { project_IDs: props.pageLoadData.projects.ids, searchTerm: props.searchTerm },
    ],
    queryFn: getSavedCards,
    // staleTime: Infinity,
  });

  return { savedCardArray: data };
};

export default useSavedCards;
