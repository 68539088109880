export const modalStyle = {
  position: "absolute",
  top: "100px",
  // bottom: "50px",
  left: "50%",
  transform: "translate(-50%, 0)",
  minWidth: .5,
  maxWidth: .8,
  minHeight: .2,
  maxHeight: .8,
  bgcolor: "background.paper",
  border: "1px solid #155436",
  boxShadow: 24,
  p: 4,
  overflow: "hidden",
};

