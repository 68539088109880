// Regex reference: https://www.w3schools.com/jsref/jsref_obj_regexp.asp

export const searchTermCleaning = (term) => {
  // Remove excess spaces
  let cleanedTerm = term.replace(/\s\s+/g, " ");

  // Remove OR
  cleanedTerm = cleanedTerm.replace(/ OR /g, " ");

  // Convert AND NOT to +-
  cleanedTerm = cleanedTerm.replace(/ AND NOT /g, " +-");

  // Convert AND to +
  cleanedTerm = cleanedTerm.replace(/ AND /g, " +");

  return cleanedTerm;
};
