import moment from "moment";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import "chartjs-adapter-date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  // Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale
  // Legend
);

import _ from "underscore";

// NEEDS CONVERSION
// The documentation on this needs to be updated from clips/highlights --> hits

function LineChart(props) {
  // CHECKPOINT: looking good but double check the numbers vs prod
  // counting different things i think

  if (props.evidenceByDate !== undefined) {
    // const clipEpochArray = [];

    const startDate = moment.unix(props.startDateRange).startOf("day");
    const endDate = moment.unix(props.endDateRange).startOf("day");

    const dataFormatted = [];

    // Initialize a moment date variable to iterate from start to end date
    let currentDate = startDate.clone();

    // Create a map for quick lookup of bucket counts by date
    const bucketMap = props.evidenceByDate.buckets.reduce((acc, bucket) => {
      const dateKey = moment(bucket.key).startOf("day").format();
      acc[dateKey] = Number(bucket.doc_count);
      return acc;
    }, {});

    // Iterate from start date to end date
    while (currentDate.diff(endDate) <= 0) {
      const dateKey = currentDate.format();
      // Use the bucket count if available, otherwise default to 0
      const count = bucketMap[dateKey] || 0;
      dataFormatted.push({ x: dateKey, y: count });
      // Move to the next day
      currentDate.add(1, "days");
    }

    const lineData = {
      // labels,
      datasets: [
        {
          datasetIdKey: "lineData",
          label: "Clips",
          data: dataFormatted,
          backgroundColor: "#155436",
          borderColor: "#155436",
          borderWidth: 1,
        },
      ],
    };

    const lineOptions = {
      responsive: true,
      plugins: {
        title: false,
        tooltip: {
          backgroundColor: "#155436",
          callbacks: {
            title: function (tooltipItems) {
              return tooltipItems[0].label.split(",")[0];
            },
          },
        },
      },
      scales: {
        yAxis: {
          min: 0,
          title: "# of Clips",
          ticks: {
            precision: 0,
          },
        },
        xAxis: {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              day: "d MMM",
            },
          },
        },
      },

    };

    return <Line data={lineData} options={lineOptions} />;
  }
}

export default LineChart;
