import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { modalStyle } from "../../Styles/modalStyle";

// import { AddNewAlert } from './AddNewAlert.js'
import BasicTabs from "./AlertsTabs";
import ModalClose from "../Navigation/ModalClose";

// const modalStyle = {
//   position: "absolute",
//   top: "100px",
//   left: "50%",
//   transform: "translate(-50%, 0)",
//   width: 950,
//   bgcolor: "background.paper",
//   border: "1px solid #155436",
//   boxShadow: 24,
//   p: 4,
// };

export const AlertsModal = (props) => {
  return (
    <div>
      <Modal open={props.showAlerts} onClose={props.handleAlertsModal}>
        <Box sx={modalStyle}>
          {" "}
          <ModalClose onClose={props.handleAlertsModal} />
          <AlertUI showAddAlert={true} newAlertQuery={props.newAlertQuery} />
        </Box>
      </Modal>
    </div>
  );
};

const AlertUI = (props) => {
  return (
    <div className="test2">
      {props.showAddAlert && <BasicTabs newAlertQuery={props.newAlertQuery} />}
    </div>
  );
};
