export default function parseSavedCards(hitArray, saved_card_array) {
  if (saved_card_array.length > 0) {
    /////
    // Add particular data (highlights, metadata) for currentHit from SavedCard data
    // Loop through all saved cards and all hits to find the correct matches (1:1)
    /////

    for (const card of saved_card_array) {
      for (const hit of hitArray) {
        if (card.open_search_id === hit.indexID) {
          hit._source.sourceMetadata.saved_pgID = card.id;
          hit._source.sourceMetadata.saved_userWhoSaved = card.username;
          hit._source.sourceMetadata.saved_projectID = card.project_id;
          hit._source.sourceMetadata.saved_searchTerm = card.search_term;

          hit["highlightArray"] = [];
          for (const highlight of card.highlight_array) {
            hit["highlightArray"].push(JSON.parse(highlight));
          }
        }
      }
    }
  }

  return hitArray;
}
