// Generate a random string of a given length
export function generateRandomString(length: number): string {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// Filter empty, flatten, dedupe, and return string arrays
export const flattenAndDedupeArrays = (...tagsArrays: string[][]): string[] => {
  const nonEmptyTagsArrays = tagsArrays.filter((tags) => tags && tags?.length > 0);
  const uniqueTags = new Set(nonEmptyTagsArrays.flatMap((tags) => tags));
  return Array.from(uniqueTags);
};
