// This function adds the metadata from PostGres (pgData) to the hits from opensearch
import { PageLoadData } from "../../Configs/interfaces";
import { Station } from "../../Models/station";

interface Props {
  pageLoadData: PageLoadData;
  hits: any;
}
export function addMetadata(props: Props) {
  // Create maps for quick lookup
  const pgMaps = {
    stationMetadata: props.pageLoadData.stations
      ? new Map(props.pageLoadData.stations.map((row: Station) => [row.stationCode, row]))
      : new Map(),
    // programMetadata: pgData.programMetadata ? new Map(pgData.programMetadata.map(row => [row.program_id, row])) : new Map(),
  };

  // Get hits from the page
  const pageHits = props.hits;

  // This forEach loop enriches each hit with corresponding station metadata from pgMaps
  pageHits.forEach((hit: any) => {
    const stationCode = hit._source.sourceMetadata.station_code;
    const stationData = pgMaps.stationMetadata.get(stationCode);
    if (stationData) {
      // const { station_code, ...restOfStationData } = stationData;
      const { station_code, ...restOfMetadata } = hit._source.sourceMetadata;
      hit._source.sourceMetadata = {
        ...restOfMetadata,
        ...stationData,
      };
    }
  });
  // Reintegrate the enriched pageHits back into the full opensearchData object
  return pageHits
}
