import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./Styles/index.css";
import App from "./App";
import AlertCards from "./AlertCards/AlertCards";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Fallback from "./Components/Navigation/Fallback";
import Upload from "./Views/Upload";
import { ThemeProvider } from "@mui/material/styles";
import { fqTheme } from "./Styles/themeCustomization";

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={fqTheme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Auth0Provider
                domain="dev-z8wgdxiu.us.auth0.com"
                clientId="NhrIkVXSsCTs9W0Ud9ImRZ9UFIK7mC0H"
                redirectUri={window.location.origin}
              >
                <Suspense fallback={<Fallback />}>
                  <App />
                </Suspense>
              </Auth0Provider>
            }
          />
           <Route
            path="/upload"
            element={
              <Auth0Provider
                domain="dev-z8wgdxiu.us.auth0.com"
                clientId="NhrIkVXSsCTs9W0Ud9ImRZ9UFIK7mC0H"
                redirectUri={window.location.origin}
              >
                <Suspense fallback={<Fallback />}>
                  <Upload />
                </Suspense>
              </Auth0Provider>
            }
          /> 
          <Route path="/alerts" element={<AlertCards />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </QueryClientProvider>
);
