// Global
import { useQueryClient } from "@tanstack/react-query";

// Invalide a query (name passed as param)
const useQueryInvalidation = (queryName: string) => {
  const queryClient = useQueryClient();


  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: [queryName] })
  }


   return {invalidate};
};

export default useQueryInvalidation;
