import { ReactNode } from "react";

import { Card } from "@mui/material";

interface Props {
  children?: ReactNode;
  isShort?: boolean;
}

const FqCard = (props: Props) => {
  return (
    <Card
      sx={{
        width: 440,
        height: props.isShort ? 260 : 300,
        marginBottom: 2,
        p: 1,
      }}
    >
      {props.children}
    </Card>
  );
};

export default FqCard;
