import { dev_v_prod } from "../Components/APICalls/dev_v_prod";

const environment = dev_v_prod();

export default async function getAlertCards(alertCardIds) {
  // This function gets saved cards from Postgres
  
  const resJSON = await (
    await fetch(environment.fqPost, {
      method: "POST",
      body: JSON.stringify({
        getAlertCards: true,
        alertCardIds: alertCardIds,
      }),
    })
  ).json();

  return resJSON.response.rows


  }