import { dev_v_prod } from "./dev_v_prod";

const environment = dev_v_prod();

export default async function postAlertCard(indexID, username, highlightArray, highlightIndex) {

// Save a new row in alert_cards
  const response = await (
    await fetch(environment.fqPost, {
      method: "POST",
      body: JSON.stringify({
        alerts: "createAlertCard",
        indexID: indexID,
        username: username,
        highlightArray: highlightArray,
        highlightIndex: highlightIndex,
      }),
    })
  ).json();

  return response.response.rows[0].id;
}
