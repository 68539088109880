// Global
import { useState, useContext } from "react";
import { downloadCSV } from "download-csv";

// MUI
import { Box, Tooltip } from "@mui/material";

// Local
import DownloadIcon from "@mui/icons-material/Download";
import exportClipToCSV, { ClipCSVHeaders } from "../DataManagement/exportClipToCSV";
import { PageLoadContext } from "../DataManagement/InitContexts";

// Local

const DownloadButton = (props) => {
  const pageLoadData = useContext(PageLoadContext);

  const [exporting, setExporting] = useState(false);

  const handleExport = async () => {
    setExporting(true);
    // This effect fires on user click. Downloads the data to CSV
    // Choose export Clips or Highlights

    if (props.hits) {
      const filename =
        props.searchTerm === "GETSAVEDCARDS"
          ? "frequency_saved_list"
          : props?.searchTerm?.slice(0, 50);
      let response;

      response = await exportClipToCSV({
        hitData: props.hits,
        user_email: pageLoadData.username || "",
      });

      downloadCSV(response, ClipCSVHeaders, filename);
      setExporting(false);
    }
  };

  return exporting ? (
    <div className={"loader l-small"} />
  ) : (
    <Box>
      <Tooltip title="Download results" placement="bottom" arrow>
        <DownloadIcon fontSize="large" color='primary' onClick={handleExport} />
      </Tooltip>
    </Box>
  );
};

export default DownloadButton;
