import { useState,  useContext} from "react";
import Avatar from "@mui/material/Avatar";

// Alert Dialogue imports
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { PageLoadContext } from "./DataManagement/InitContexts";
import { useAuth0 } from "@auth0/auth0-react";



// export const LoginButton = () => {
//   const { loginWithRedirect } = useAuth0();

//   return <button onClick={() => loginWithRedirect()}>Log In</button>;
// };

// This block shows the blank avatar icon
export const UserOptions = () => {
  const { user, logout } = useAuth0();

  const pageLoadData = useContext(PageLoadContext);

  const [showUserDialog, setShowUserDialog] = useState(false);


  const handleDialogClose = () => {
    setShowUserDialog(false);
  };

  return (
    <div>
      <div className="inline-div" onClick={() => setShowUserDialog(true)}>
        {/* Log out {shortName} */}
        <Avatar className="link" alt={user?.name} />
      </div>
      {showUserDialog && <UserDialog pageLoadData={pageLoadData} handleDialogClose={handleDialogClose} />}
    </div>
  );
};

// This block is revealed when clicking on the avatar icon
// It provides information about and options for users
// Including log out
const UserDialog = (props: any) => {
  const [open, setOpen] = useState(true);

  const { user, logout } = useAuth0();

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    props.handleDialogClose();
    setOpen(false);
  };


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">User Information</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div>Username: {user?.name}</div>
          <div className="mt10">Project(s): {props.pageLoadData.projects.names.toString().split(", ")} </div>
          <div className="mt10">To reset password, log out and use reset password. </div>
          <div className="mt10">
            For any questions or assistance, email{" "}
            <div
              className="inline-div link"
              onClick={() => window.location.assign("mailto:support@rootwise.co")}
            >
              support@rootwise.co
            </div>
            .
          </div>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
          <Button variant="contained" onClick={() => logout({ returnTo: window.location.origin })}>
            Log Out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
