// Global
import { useState } from "react";

// Local

// Filter object
export interface FilterObj {
  country: string;
  label: string;
  value: string;
}

export enum FilterTypes {
  Countries = "countries",
  Stations = "stations",
}

export interface FilterProps {
  countries?: FilterObj[];
  stations?: FilterObj[];
}

export interface FilterControls {
  filters: FilterProps | undefined;
  filterOptions: FilterProps | undefined;
  setNewFilterOptions: (values: FilterObj[] | undefined) => void;
  setFilters: (value: undefined) => void;
  updateFilters: (type: FilterTypes, values: FilterObj[]) => void;
}

// Manage clip filters (stations, countries)
const useFilters = () => {
  const [filterOptions, setFilterOptions] = useState<FilterProps | undefined>(undefined);
  const [filters, setFilters] = useState<FilterProps | undefined>(undefined);

  const setNewFilterOptions = (values: FilterObj[] | undefined) => {
    // setFilters(undefined);
    if (values === undefined) {
      setFilterOptions(undefined);
    } else {
      setFilterOptions({
        countries: values,
        stations: values,
      });
    }
  };

  // Handle user inputs
  const updateFilters = (type: FilterTypes, values: FilterObj[]) => {
    const newFilters: FilterProps = {...filters, [type]: values}

    setFilters(newFilters);
  };

  return {
    filters,
    filterOptions,
    setNewFilterOptions,
    setFilters,
    updateFilters,
  };
};

export default useFilters;
