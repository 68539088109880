// Global
import { useState, Suspense, useEffect } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

// Local
import { Navbar } from "./Components/Navigation/navbar";
import {
  SearchTermContext,
  PageLoadContext,
  DateObjContext,
} from "./Components/DataManagement/InitContexts";

import "./Styles/index.css";
import "./Styles/gridLayout.css";



import useDateRange from "./Hooks/useDateRange";
import { usePageLoadData } from "./Hooks/usePageLoadData";
import Fallback from "./Components/Navigation/Fallback";
import ViewClips from "./Views/ViewClips";
import useFilters from "./Hooks/useFilters";

// Auth0 function
export default withAuthenticationRequired(Main, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <div className="loader l-normal" />,
});

// Main app function
export function Main() {
  // Get initial page data
  const { pageLoadData } = usePageLoadData();

  // State for Search term and filters
  const [searchTerm, setSearchTerm] = useState("");
  const filterControls = useFilters();
  const dateObj = useDateRange({
    setFilters: filterControls.setFilters,
    setNewFilterOptions: filterControls.setNewFilterOptions,
  });

  useEffect(() => {
    if (searchTerm === "" && pageLoadData?.prefabQueries && pageLoadData.prefabQueries.length > 0) {
      setSearchTerm(pageLoadData.prefabQueries[0].query);
    }
  }, [pageLoadData]);

  const handleSearchSubmit = (searchTermSubmitted: string) => {
    setSearchTerm(searchTermSubmitted);
    filterControls.setFilters(undefined);
    filterControls.setNewFilterOptions(undefined);
  };

  /////////////
  // Render
  /////////////

  return (

      <DateObjContext.Provider value={dateObj}>
        <SearchTermContext.Provider value={searchTerm}>
          <PageLoadContext.Provider value={pageLoadData}>
            <div className="fq-container">
              {/* Header */}
              <div className={"header"}>
                <Navbar onSearchSubmit={handleSearchSubmit} />
              </div>

              <div className="line-separator" />

              {/* Main stage */}
              <div className={"main-stage"}>
                <Suspense fallback={<Fallback />}>
                  <ViewClips filterControls={filterControls} />
                </Suspense>
              </div>
            </div>
          </PageLoadContext.Provider>
        </SearchTermContext.Provider>
      </DateObjContext.Provider>
 
  );
}
