// Data Intensities
export enum DataIntensity {
  "High" = "High",
  "Low" = "Low",
}

// Calculate standard deviation for data objects
// Return intensity high, low or undefined on each object
const calcMarkerIntensity = (
  data:
    | { [key: string]: { name: string; count: number; lat: number; lng: number } }
    | { count: number; lat: number; lng: number }[]
) => {
  // Convert data to array if it's in object form
  const dataArray = Array.isArray(data) ? data : Object.values(data);

  // Calculate the AVERAGE value
  const sum = dataArray.reduce(
    (accumulator: number, obj: { count: number }) => accumulator + obj.count,
    0
  );
  const average = sum / dataArray.length;

  // Get the STND DEVIATION
  const squaredDifferences = dataArray.map((obj: { count: number }) => Math.pow(obj.count - average, 2));
  const sumOfSquaredDifferences = squaredDifferences.reduce(
    (accumulator, value) => accumulator + value,
    0
  );
  const variance = sumOfSquaredDifferences / dataArray.length;
  const standardDeviation = Math.sqrt(variance);

  // Data higher/lower than standard deviation marked
  // Using "intensity" property
  const deviatedObjects = dataArray.map((obj: { count: number; lat: number; lng: number }) => {
    const isHigh = obj.count > average + standardDeviation;
    const isLow = obj.count < average - standardDeviation;
    return {
      ...obj,
      intensity: isHigh ? DataIntensity.High : isLow ? DataIntensity.Low : undefined,
    };
  });

  return deviatedObjects;
};

export default calcMarkerIntensity;
