import axios, { AxiosResponse } from "axios";

// Local
import { dev_v_prod } from "../APICalls/dev_v_prod";
import getMaxPGID from "../APICalls/getMaxPGID";

interface Props {
  action: "addQueryForTeam" | "addQueryForUser" | "hideQueryForUser";
  userId?: number;
  projectId?: number;
  queryId?: number;
  queryData?: { displayOrder: number; queryName: string; queryString: string };
}

// POST data edits to API endpoint for postgres
const updateQueries = async ({ action, userId, projectId, queryId, queryData }: Props) => {
  const environment = dev_v_prod();

  // To hideQueryForUser: add to blacklist, remove from whitelist
  if (action === "hideQueryForUser") {
    const hideQueryForUserPromise = axios.post(environment.fqPost, {
      queryId,
      userId,
      queryEdit: "hideQueryForUser",
    });
    const removeQueryFromWhitelistPromise = axios.post(environment.fqPost, {
      queryId,
      userId,
      queryEdit: "removeQueryFromWhitelist",
    });

    await Promise.all([hideQueryForUserPromise, removeQueryFromWhitelistPromise])
      .then((responses) => {
        const allResponsesSuccessful = responses.every((response) => response.status === 200);
        if (!allResponsesSuccessful) {
          throw new Error("Failed to update queries successfully.");
        }
        return 200;
      })
      .catch((error) => {
        throw new Error(`Error updating queries: ${error.message}`);
      });
  }

  // To addQueryForTeam or addQueryForUser
  if (action.startsWith("addQuery")) {
    const response = await axios.post(environment.fqPost, {
      ...queryData,
      queryEdit: "addQuery",
    });

    // Get ID of newly-added query
    const newId = await getMaxPGID("prefab_queries");

    if (action === "addQueryForTeam") {
      const response = await axios.post(environment.fqPost, {
        // TODO: checkpoint --> get entity ID project/user and maxid
        queryId: newId,
        projectId,
        queryEdit: action,
      });
    }

    else if (action === "addQueryForUser") {
      const response = await axios.post(environment.fqPost, {
        queryId: newId,
        userId,
        queryEdit: action,
      });
    }
  }

  // for add, get query, then maxId, then add to whitelist or project

  // Response 200 stuff?
};

export default updateQueries;

// action: "addQuery" | "addQueryForTeam" | "addQueryForUser" | "hideQueryForUser" | "removeQueryFromWhitelist";
