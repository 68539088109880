import { dev_v_prod } from "./dev_v_prod";

const environment = dev_v_prod();

export default async function postAlertCardForExport(user_email, hit) {

  // Save a new row in alert_cards
  const response = await (
    await fetch(environment.fqPost, {
      method: "POST",
      body: JSON.stringify({
        alerts: "createMultipleAlertCards",
        indexID: hit.indexID,
        createdBy: "EXPORT | " + user_email,
        highlightArray: hit.highlightArray,
        export: true,
      }),
    })
  ).json();

  // Return the Postgres ID
  return response.response.rows[0].id;
}
