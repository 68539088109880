// Global
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

// MUI
import { Box } from "@mui/material";

// Local
import { HitCard } from "./HitCard";

interface CardArrayProps {
  clipData: any; // Ideally, replace 'any' with a more specific type

  fetchControls: {
    hasNextPage: boolean;
    fetchNextPage: () => void;
    isFetchingNextPage: boolean;
  };
}

// Map and render all the matching cards.
// Trigger a Load More worflow as needed.
export const CardArray = (props: CardArrayProps) => {
  if ((props.clipData?.hits?.length ?? 0) < 1) {
    return <div>No results. Try adjusting your search or filters.</div>;
  }
  // Trigger fetchNextPage() on observation ref clip
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView && props.fetchControls.hasNextPage) {
      props.fetchControls.fetchNextPage();
    }
  }, [inView, props.fetchControls.fetchNextPage, props.fetchControls.hasNextPage]);

  return (
    <Box overflow={'auto'} height='100%' pr={0.5}>
      {/* Map Hit Cards. note 3rd to last as ref */}

      {props.clipData.hits.map((hit: any, i: number) => {
        if (i === props.clipData.hits.length - 3) {
          return (
            <div ref={ref} key={hit.indexID}>
              <HitCard values={hit} />
            </div>
          );
        }
        return (
          <div key={hit.indexID}>
            <HitCard values={hit} />
          </div>
        );
      })}
      {/* Conditional loader */}
      {props.fetchControls.isFetchingNextPage ? <div className={"loader l-normal"} /> : null}
    </Box>
  );
};
